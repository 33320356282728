import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { ContentOffice, OfficeResponse } from "../../types/Office";

export const getNotification = async (page: number, size?: number): Promise<OfficeResponse> => {

  try {
    const response: AxiosResponse<OfficeResponse> = await privateFetch.get(
      URL.REQUEST_NOTIFICATION_LIST(
        page,
        size || UrlBase.registration_x_page
      ),
    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error("Error al obtener la lista de notificaciones");

  }
};

export const createNotification = async (data: any) => {
  const dataFinish = {
    ...data,
    allYears: true,
    allMonths: true,
  }
  try {
    const response: AxiosResponse<ContentOffice> = await privateFetch.post(URL.REQUEST_NOTIFICATION_CREATE, dataFinish, );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error al crear la notificación");
  }
};

export const updateNotification = async (data: any): Promise<ContentOffice> => {
  try {
    const dataFinish = {
      ...data,
      allYears: true,
      allMonths: true,
    }


    const response = await privateFetch.put(
      URL.REQUEST_NOTIFICATION_UPDATE(data.id),
      dataFinish,
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error al actualizar la notificación");
  }
};


export const deleteNotification = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_NOTIFICATION_DELETE(id), {});
    return "deleted successfully"
  } catch (error) {
    console.log(error);
    throw new Error("Error al eliminar la notificación");
  }
};

