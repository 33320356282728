import { CONSTANT } from "./Constants";
export const URL = {

    /** HOME PATH */
    LOGIN_TWOFACTORCODE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/login/twofactor",
    LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/autenticate",
    LOGIN_RECOVERY_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/recoverPasswordAdmin",
    LOGIN_CHANGE_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/changePassword",
    LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/activate",
    SESSIONVALIDATE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

    /** maintenance urls */
    REQUEST_CERTCATEGORY_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${page}/${size}`,
    REQUEST_CERTCATEGORY: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${id ? id : ''}`,

    REQUEST_CERTIFICATE_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${page}/${size}`,
    REQUEST_CERTIFICATE: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ''}`,

    // Assets

    REQUEST_ASSET_LIST:  CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/`,
    REQUEST_ASSET_LIST_ALL: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/findAll`,
    REQUEST_ASSET_GET_BY_ID: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/${id}`,
    REQUEST_ASSET_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets`,
    REQUEST_ASSET_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/${id}`,
    REQUEST_ASSET_SIMULATOR: (id: string ) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/simulator/${id}`,
    REQUEST_ASSET_EXCEL: (date: string, ids : string ) =>  CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/simulator?date=${date}&${ids}`,
    REQUEST_ASSET_UPDATE_MASIVE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/updateDepreciation`,
    REQUEST_ASSET_SERIE_VERIFICATION: (id: string ) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/serieVerification/${id}`,

    // Assets OutStanding

    REQUEST_ASSET_OUTSTANDING_LIST : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/outstandingAssets/`,
    REQUEST_ASSET_OUTSTANDING_BY_ID: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/outstandingAssets/${id}`,
    REQUEST_ASSET_OUTSTANDING: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets-outstanding/${id ? id : ''}`,
    REQUEST_ASSET_OUTSTANDING_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/outstandingAssets`,
    REQUEST_ASSET_OUTSTANDING_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/outstandingAssets/${id}`,

    // Notification
    REQUEST_ASSET_OUTSTANDING_CREATE_NOTIFICATION: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/notification/reasignedAsset`,
    REQUEST_ASSET_OUTSTANDING_CREATE_NOTIFICATION_NEW_ASSET: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/notification/createdAsset`,

    // Maintenance Area

    REQUEST_AREA_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/area/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_AREA_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/area/create`,
    REQUEST_AREA_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/area/${id}`,

    // Maintenance Category

    REQUEST_CATEGORY_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_CATEGORY_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category`,
    REQUEST_CATEGORY_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/${id}`,

    // Maintenance Office

    REQUEST_OFFICE_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/office/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_OFFICE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/office`,
    REQUEST_OFFICE_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/office/${id}`,

    // Maintenance Notification

    REQUEST_NOTIFICATION_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/calendar-notification/find?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_NOTIFICATION_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/calendar-notification/create`,
    REQUEST_NOTIFICATION_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/calendar-notification/delete/${id}`,
    REQUEST_NOTIFICATION_UPDATE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/calendar-notification/update/${id}`,

    // Maintenance Provider

    REQUEST_PROVIDER_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/provider/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_PROVIDER_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/provider`,
    REQUEST_PROVIDER_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/provider/${id}`,

    // Maintenance Usuario final

    REQUEST_ENDUSER_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/final-user/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_ENDUSER_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/final-user`,
    REQUEST_ENDUSER_UPDATE_DELETE:(id: number,) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/final-user/${id}`,
    REQUEST_ENDUSER_DELETE:(id: number, createdUser: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/final-user/${id}/?createUser=${createdUser}`,

    REQUEST_ASSIGNED_ASSET: (id: number,) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/assignedAssets/${id}`,

    /** users urls */
    
    REQUEST_USER_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/{page}/{size}/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_USER_LIST_FILTER: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
    REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
    REQUEST_USER: (id:any) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ''}`,
    REQUEST_USER_CHANGE_PASSWORD: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
    REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,

  


    /** Role urls */
    REQUEST_ROLE: (id:number | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ''}`,
    REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/create`,
    REQUEST_ROLE_UPDATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/`,

    

    /** EmailRole urls */
    REQUEST_EMAIL_ROLE: (roleID?:string|null, filter?:string|null, currentPage?:number, size?:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${roleID ? roleID+'/' : ''}?${filter ? 'filter='+filter+'&' : ''}page=${currentPage}&size=${size}`,
    REQUEST_EMAIL_ROLE_ID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/findById/${id ? id : ''}`,
    DELETE_REQUEST_EMAIL_ROLE_ID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${id ? id : ''}`,
    REQUEST_EMAIL_ROLE_CU: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/`,

    /** DocumentRole urls */
    REQUEST_DOCUMENT_ROLE_LIST: (filter:string|null, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/list/?${filter ? 'filter='+filter+'&' : ""}page=${page}&size=${size}`,
    REQUEST_DOCUMENT_ROLE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/${id ? id : ''}`,


    /** document */
    REQUEST_DOCUMENT_LIST: (page:number, size:string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/list/?page=${page}&size=${size}`,
    REQUEST_DOCUMENT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/${id ? id : ""}`,
    REQUEST_DOCUMENT_UPD: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/{id}`,

    /** authority */
    REQUEST_AUTHORITY_LIST: (filter:string, page:number, size:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/?${filter ? 'filter='+filter+'&' : ""}page=${page}&size=${size}&sort=id,desc`,    
    REQUEST_AUTHORITY: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
    REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (roleId:number, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${roleId}/${page}/${size}?filter=${filter}`,

   

    /** authorityByRole */    
    REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${roleId}/${page}/${size}${filter && '?filter='+filter}`,
    /* REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${roleId}/${page}/${size}${filter && 'filter='+filter}`, */
    
    
    REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,

    REQUEST_CLIENT_LIST: (endDate:any, page:number, size:string, startDate:any, ) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/{page}/{size}/?endDate=${endDate}&page=${page}&size=${size}&startDate=${startDate}`,
    REQUEST_CLIENT: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id ? id : ''}`,
    /* REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}/${page}/${size}`,   */
    REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}?page=${page}&size=${size}`,  
    
    
    /**Dashboard */
  REQUEST_CATEGORY_COUNT: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/category-count`,
  REQUEST_USER_WITH_ASSETS : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/users-with-assets`,
  REQUEST_ASSETS_BY_CATEGORY : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/assets-by-category`,
  REQUEST_PROVIDER_COUNT : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/provider-count`,
  REQUEST_ASSETS_BY_MONTH : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/assets-by-month`,


    REQUEST_CUSTOMER_STAGE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-stage`,      
    REQUEST_CUSTOMER_PERSON_TYPE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-person-type`,  
    REQUEST_CUSTOMER_REGISTERED: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer`,  
    REQUEST_CUSTOMER_COMPARATION: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/clients-comparation`,     
    REQUEST_CUSTOMER_YEAR: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer-year`,     
    REQUEST_CUSTOMER_LEADS: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/leads`,     
}

