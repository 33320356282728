import { useState, useEffect } from 'react';
import { useApp } from '../../hooks/useApp';
import {
  Paper,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Stack,
  TextField,
  Divider,
} from '@mui/material';
import FancyTooltip from './FancyTooltip';
import FancyTablePagination from './FancyTablePagination';
//import { useForm, Controller } from "react-hook-form";
import { getRequestNoAdded } from '../../services/authority/AuthorityService';
import { CircleCheckIcon } from '../svgicons/SvgIcons';

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));

interface AddAuthorityByRole {
  id?: number;
  authorityId: string;
  roleId: string;
}

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

interface DialogProps {
  //actionButton?: any;
  data: any;
  cancelModal: any;
  //modalType?: any;
  //handleAdd: any;
  onSubmit: any;
}

const AddAuthorityByRole = ({
  //actionButton,
  cancelModal,
  //modalType,
  //handleAdd,
  onSubmit,
  data,
}: DialogProps) => {
  const theme = useTheme();
  const { setErrorMsg, setSuccessMsg } = useApp();
  const [customLoading, setCustomLoading] = useState<boolean>();
  const [authorityData, setAuthorityData] = useState<any>(null);
  const [preFilter, setPreFilter] = useState<any>('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(data, 0, 5, '');
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (
    roleId: number,
    currentPage: number,
    size: number,
    filter: string
  ) => {
    setCustomLoading(true);
    try {
      setPage(currentPage);
      let data = await getRequestNoAdded(roleId, currentPage, size, filter);
      if (data) {
        setAuthorityData(data);
      }
      setCustomLoading(false);
    } catch (error: any) {
      setCustomLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(data, customPage, 5, '');
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== '') {
      await handleFetchData(data, 0, 5, preFilter);
    }
  };

  return (
    <>
      <Title id='alert-dialog-title' sx={{ pt: 4, px: 4 }}>
        Agregar Permiso
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={8} sx={{ pt: 8, pb: 4 }}>
          <Grid item sm={6} xs={12}>
            <Stack direction='row' alignItems='center' justifyContent='flex-end'>
              <TextField
                placeholder='Filtro de búsqueda'
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    borderRadius: '0.2rem 0 0 0.2rem',
                  },
                  '& fieldset': {
                    borderRightWidth: '0',
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === '') {
                    handleFetchData(data, 0, 5, '');
                  }
                }}
                onKeyDown={e => e.key === 'Enter' && handleApplyFilter()}
              />
              <Button
                variant='contained'
                color='secondary'
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: '0 0.2rem 0.2rem 0!important',
                  padding: '1.5px 16px!important',
                }}>
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 4 }} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Código</TableCell>
                <TableCell align='center'>Descripción</TableCell>
                <TableCell align='center'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!customLoading &&
                authorityData &&
                authorityData.content &&
                authorityData.content.map((r: any) => (
                  <TableRow key={r.id}>
                    <TableCell component='th' scope='row'>
                      {r.id}
                    </TableCell>
                    <TableCell>{r.code}</TableCell>
                    <TableCell>{r.description}</TableCell>
                    <TableCell align='center'>
                      <FancyTooltip title='Agregar permiso' placement='top'>
                        <IconButton
                          aria-label='create'
                          component='label'
                          color='secondary'
                          sx={{
                            '&:hover': {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          onClick={onSubmit}
                          data-name='create'
                          data-id={r.id}
                          className='ana'>
                          <CircleCheckIcon sx={{ pointerEvents: 'none' }} />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={authorityData?.content?.length > 0 ? authorityData?.content?.length : 0}
          rowsPerPage={authorityData?.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={authorityData?.totalElements}
          totalPages={authorityData?.totalPages}
        />
      </DialogContent>
      <Actions sx={{ mt: 4, pb: 4, px: 4 }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          Cancelar
        </Button>{' '}
      </Actions>
    </>
  );
};

export default AddAuthorityByRole;
