import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { UrlBase } from "../../url/Urls";
import { BodyArea, } from "../../types/Area";
import { AxiosResponse } from "axios";
import { AssetsIdResponse, AssetsResponse, ContentAssets } from "../../types/Activo";
import { CONSTANT } from "../../utils/Constants";

interface filtro {
  code?: string;
  category?: string;
  office?: string;
  area?: string;
  provider?: string;
  finalUser?: string;
  scheme: string;
  status?: string;
  buyDate?: string | null;
  endDate?: string | null;
  sort?: string;
}

export const getActivos = async (page: number, size?: number, filter?: filtro, userNull?: boolean): Promise<AssetsResponse> => {
  const params = new URLSearchParams([
    ['page', page.toString()],
    ['size', size ? size.toString() : UrlBase.registration_x_page],
    ['code', filter?.code ? filter.code : ''],
    ['category', filter?.category ? filter.category : ''],
    ['office', filter?.office ? filter.office : ''],
    ['area', filter?.area ? filter.area : ''],
    ['provider', filter?.provider ? filter.provider : ''],
    ['finalUser', filter?.finalUser ? filter.finalUser : ''],
    ['sort', 'id,desc']


  ])

  if(filter?.status){
    params.append('status', filter.status)
  }


  if (filter?.buyDate && filter?.endDate) {
    params.append('buyDate', filter.buyDate)
    params.append('endDate', filter.endDate)
  }


  if (filter?.scheme) {
    params.append('scheme', filter.scheme === "Leasing" ? "leasing" : "activoPropio")
  }

  if (userNull === true) {
    params.append('finalUserNull', "true")
  }



  try {
    const response: AxiosResponse<AssetsResponse> = await privateFetch.get(
      URL.REQUEST_ASSET_LIST,
      {
        params,
      }

    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_assets_find_error);

  }
};

export const getDataToExcel = async (date: string, ids: string): Promise<ContentAssets[]> => {

  try {

    const response: AxiosResponse<ContentAssets[]> = await privateFetch.get(
      URL.REQUEST_ASSET_EXCEL(date, ids),


    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_assets_find_error);

  }
};

export const getAllActivos = async (): Promise<ContentAssets[]> => {
  try {
    const response: AxiosResponse<ContentAssets[]> = await privateFetch.get(
      URL.REQUEST_ASSET_LIST_ALL()
    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_assets_find_error);
  }
};


export const getSimulator = async (id: string, date: string): Promise<AssetsResponse> => {
  const params = new URLSearchParams([
    ['date', date],
  ])

  try {
    const response: AxiosResponse<AssetsResponse> = await privateFetch.get(
      URL.REQUEST_ASSET_SIMULATOR(id),
      {
        params,
      }

    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_assets_find_error);

  }
};


export const getActivoById = async (id: number): Promise<AssetsIdResponse> => {
  try {
    const response: AxiosResponse<AssetsIdResponse> = await privateFetch.get(
      URL.REQUEST_ASSET_GET_BY_ID(id)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_find_error);
  }
};

export const createActivo = async (data: BodyArea): Promise<ContentAssets> => {
  console.log(data);
  try {
    const response: AxiosResponse<ContentAssets> = await privateFetch.post(
      URL.REQUEST_ASSET_CREATE,
      data,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_save_error);
  }
};


export const updateActivo = async (data: any): Promise<ContentAssets> => {
  try {
    const response = await privateFetch.put(
      URL.REQUEST_ASSET_UPDATE_DELETE(data.id),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_update_error);
  }
};

export const updateMasive = async (): Promise<void> => {
  try {
    await privateFetch.post(
      URL.REQUEST_ASSET_UPDATE_MASIVE,
      {},
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_update_error);
  }
}

export const getSerieVerification = async (id:string) => {
  let responseData = {};  
  await privateFetch
    .get(URL.REQUEST_ASSET_SERIE_VERIFICATION(id))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });

  return responseData;
};




// export const deleteArea = async (id: number): Promise<string> => {
//   try {
//     await privateFetch.delete(URL.REQUEST_ASSET_UPDATE_DELETE(id), {});
//     return "deleted successfully"
//   } catch (error) {
//     console.log(error);
//     throw new Error(properties.com_parval_label_area_delete_error);
//   }
// };

