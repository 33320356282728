import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { AssetsIdResponse, AssetsResponse, ContentAssets } from "../../types/Activo";
import { CONSTANT } from "../../utils/Constants";

interface filtro {
  code?: string;
  category?: string;
  office?: string;
  area?: string;
  provider?: string;
  finalUser?: string;
  scheme: string;
  status?: string;
}

export const getActivosOutstanding = async (page: number, size?: number, filter?: filtro): Promise<AssetsResponse> => {
  const params = new URLSearchParams([
    ['page', page.toString()],
    ['size', size ? size.toString() : UrlBase.registration_x_page],
    ['sort', 'id,desc']


  ])

  try {
    const response: AxiosResponse<AssetsResponse> = await privateFetch.get(
      URL.REQUEST_ASSET_OUTSTANDING_LIST,
      {
        params,
      }

    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_assets_find_error);

  }
};

export const createActivoOutstanding = async (data: any): Promise<ContentAssets> => {
  try {
    const response: AxiosResponse<ContentAssets> = await privateFetch.post(
      URL.REQUEST_ASSET_OUTSTANDING_CREATE,
      data,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_save_error);
  }
};

export const createNotificacion = async (name: string, email: string) => {
  const params = new URLSearchParams([
    ['roles', "26"],
    ['roles', "36"],
  ])

  const data = {
    name,
    email,
  }

  try {
    const response: AxiosResponse<any> = await privateFetch.post(
      URL.REQUEST_ASSET_OUTSTANDING_CREATE_NOTIFICATION,
      data,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_save_error);
  }
}

export const createNotificacionNewActivo = async (name: string, email: string) => {
  const params = new URLSearchParams([
    ['roles', "26"],
    ['roles', "36"],
  ])

  const data = {
    name,
    email,
  }

  try {
    const response: AxiosResponse<any> = await privateFetch.post(
      URL.REQUEST_ASSET_OUTSTANDING_CREATE_NOTIFICATION_NEW_ASSET,
      data,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_save_error);
  }
}

export const updateActivoOutstanding = async (data: any): Promise<ContentAssets> => {
  try {
    const response = await privateFetch.put(
      URL.REQUEST_ASSET_OUTSTANDING_UPDATE_DELETE(data.id),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_update_error);
  }
};

export const getActivoOutstandingById = async (id: number): Promise<AssetsIdResponse> => {
  try {
    const response: AxiosResponse<AssetsIdResponse> = await privateFetch.get(
      URL.REQUEST_ASSET_OUTSTANDING_BY_ID(id)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_assets_find_error);
  }
};


