import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { BodyProvider, ContentProvider, ProviderResponse } from "../../types/Provider";

export const getProviders = async (page: number, size?: number, filtro?: string): Promise<ProviderResponse> => {

  try {
    const response: AxiosResponse<ProviderResponse> = await privateFetch.get(
      URL.REQUEST_PROVIDER_LIST(
        page,
        size || UrlBase.registration_x_page
      ),
      {
        params: {
          filter: filtro || ""
        }
      }
    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_provider_find_error);

  }
};

export const createProvider = async (data: BodyProvider): Promise<ContentProvider> => {
  try {
    const response: AxiosResponse<ContentProvider> = await privateFetch.post(URL.REQUEST_PROVIDER_CREATE, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_provider_save_error);
  }
};

export const updateProvider = async (data: BodyProvider): Promise<ContentProvider> => {
  try {
    const response = await privateFetch.put(
      URL.REQUEST_PROVIDER_UPDATE_DELETE(data.id),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_provider_update_error);
  }
};


export const deleteProvider = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_PROVIDER_UPDATE_DELETE(id), {});
    return "deleted successfully"
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_provider_delete_error);
  }
};

