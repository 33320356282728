import {
  HouseIcon,
  GearIcon,
  TableIcon,
  FromBracketIcon,
} from '../components/svgicons/SvgIcons';
import { properties } from './Properties_es';

import { MenuItem } from '../types/Menu';

export const MENUITEMS: MenuItem[] = [
  {
    label: 'Inicio',
    icon: <HouseIcon sx={{ transform: 'translateY(-1px)', fontSize: '0.9225rem' }} />,
    to: '/',
    permissions: ['is_superuser', 'is_user'],
  },
  {
    label: 'Formulario de Activos Fijos',
    icon: <TableIcon />,
    to: null,
    permissions: ['is_superuser', 'ACTIVO:READ', 'ACTIVO:WRITE'],
    expand: true,
    children: [
      {
        label: 'Agregar un activo',
        to: 'formularioActivos/create',
        permissions: ['is_superuser', 'ACTIVO:WRITE', ] ,
      },
      {
        label: 'Agregar un activo',
        to: 'createActivo',
        permissions: ['is_superuser', 'ACTIVOTI:WRITE',],

      },
      {
        label: 'Activos pendientes por asignar',
        to: 'activosNoAsignados',
        permissions: ['is_superuser', 'ACTIVONA:READ',],

      },
      {
        label: 'Reporte de activos',
        to: 'formularioActivos',
        permissions: ['is_superuser', 'ACTIVO:READ',],
      },
      {
        label: 'Pendiente de Aprobación',
        to: 'pendingApproval',
        permissions: ['is_superuser', 'ACTIVOAPPROVAL:READ',],
      }
    ]
  },
  {
    label: 'Mantenimientos',
    icon: <TableIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: 'Oficinas',
        to: 'officeManagement',
        permissions: ['is_superuser', 'OFFICE:READ'],
      },
      {
        label: 'Proveedores',
        to: 'providerManagement',
        permissions: ['is_superuser', 'PROVIDER:READ'],
      },
      {
        label: 'Areas',
        to: 'areaManagement',
        permissions: ['is_superuser', 'AREA:READ'],
      },
      {
        label: 'Categorias',
        to: 'categoryManagement',
        permissions: ['is_superuser', 'CATEGORY:READ'],
      },
      {
        label: 'Usuarios Finales',
        to: 'endUserManagement',
        permissions: ['is_superuser', 'ENDUSER:READ'],
      },
    ],
    permissions: ['is_superuser', 'OFFICE:READ', 'PROVIDER:READ', 'AREA:READ', 'CATEGORY:READ', 'ENDUSER:READ'],
  },
  {
    label: 'Configuraciones',
    icon: <GearIcon sx={{ transform: 'translateY(-1px)', fontSize: '1.025rem' }} />,
    to: null,
    expand: true,
    children: [
      {
        label: 'Usuarios',
        to: 'userManagement',
        permissions: [
          'is_superuser',
          'USER:READ'
        ],
      },
      {
        label: 'Perfiles',
        to: 'roleManagement',
        permissions: [
          'is_superuser',
          'ROLE:READ'
        ],
      },
      {
        label: 'Permisos',
        to: 'authority',
        permissions: [
          'is_superuser',
          'AUTHORITY:READ'
        ],
      },
      {
        label: 'Configuración de alertas',
        to: 'notificaciones',
        permissions: [
          'is_superuser',
          'NOTI:READ'
        ],
      },
    ],
    permissions: [
      'is_superuser',
      'USER:READ',
      'ROLE:READ',
      'AUTHORITY:READ',
      'AUTHORITIESXROLE:READ',
      'NOTI:READ'
    ],
  },
  {
    label: properties.com_parval_label_dashboard_menu_logout,
    icon: <FromBracketIcon />,
    to: null,

    permissions: ['is_superuser', 'is_user'],
  },
];
