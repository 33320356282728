import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
// import { AreaResponse, BodyArea, Content } from "../../types/Area";
import { AxiosResponse } from "axios";
import { BodyOffice, ContentOffice, OfficeResponse } from "../../types/Office";

export const getOffices = async (page: number, size?: number, filtro?: string): Promise<OfficeResponse> => {

  try {
    const response: AxiosResponse<OfficeResponse> = await privateFetch.get(
      URL.REQUEST_OFFICE_LIST(
        page,
        size || UrlBase.registration_x_page
      ),
      {
        params: {
          filter: filtro || ""
        }
      }


    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_office_find_error);

  }
};

export const createOffice = async (data: BodyOffice): Promise<ContentOffice> => {
  try {
    const response: AxiosResponse<ContentOffice> = await privateFetch.post(URL.REQUEST_OFFICE_CREATE, {
      ...data
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_office_save_error);
  }
};

export const updateOffice = async (data: BodyOffice): Promise<ContentOffice> => {
  try {
    const requestBody = {
      name: data.name,
      description: data.description,
      // area: data.area
    };

    const response = await privateFetch.put(
      URL.REQUEST_OFFICE_UPDATE_DELETE(data.id),
      JSON.stringify(requestBody),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_office_update_error);
  }
};


export const deleteOffice = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_OFFICE_UPDATE_DELETE(id), {});
    return "deleted successfully"
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_office_delete_error);
  }
};

