import React from 'react';
import { useApp } from '../../hooks/useApp';

interface Resources {
  pathOrCode: string;
  pathOrCodeOpt?: string;
  children?: any;
  isCode: any;
}

const ResourceAccess = ({ pathOrCode, pathOrCodeOpt, children, isCode }: Resources) => {
  const { authInfo } = useApp();

  //validate authority
  const authority =
    !!authInfo?.userRole?.authorityRoles 
      ? authInfo?.userRole?.authorityRoles.find((a: any) =>
          isCode
            ? a.authority.code === pathOrCode || a.authority.code === pathOrCodeOpt
            : a.authority.path === pathOrCode
        )
      : null;

  if (authority) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

export default ResourceAccess;
