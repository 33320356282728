export const validationNumberNegative = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (...event: any[]) => void) => {
  const newValue = e.target.value;

  if (newValue === '' || (!/^-?\d*\.?\d*$/.test(newValue))) {
    onChange(0);
  } else if (newValue.startsWith('0') && newValue !== '0' && !newValue.startsWith('0.')) {
    onChange(newValue.replace(/^0+/, ''));
  } else if (newValue === '0' || /^-?\d*\.?\d*$/.test(newValue)) {
    const parsedValue = parseFloat(newValue);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      onChange(parsedValue);
    }
  }
}