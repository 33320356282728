import { URL } from '../../utils/UrlConstants';
import { privateFetch } from '../../utils/CustomFetch.js';
import { CONSTANT } from '../../utils/Constants';
import { UrlBase } from '../../url/Urls';

export const getRequest = async (roleId: string, page: number, filter: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_AUTHORITY_BY_ROLE_LIST(roleId, page, UrlBase.registration_x_page, filter))
    .then(async response => {
      responseData = await response.data;
    })
    .catch(error => {
      throw new Error('Error en consulta de permisos por perfil');
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ['authorityId', data.authorityId],
    ['roleId', data.roleId],
  ]);

  await privateFetch
    .post(
      URL.REQUEST_AUTHORITY_BY_ROLE(null),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error('Error en proceso de guardar permiso por perfil');
    });
  return responseData;
};

export const deleteRequest = async (data: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ['authorityId', data.authorityId],
    ['roleId', data.roleId],
  ]);
  await privateFetch
    .delete(URL.REQUEST_AUTHORITY_BY_ROLE(null), { params })
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error('Error en proceso de eliminar permiso por perfil');
    });
  return responseData;
};
