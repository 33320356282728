import { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  styled,
  useTheme,
  Button,
  Box,
  Paper,
  TextField,
  DialogActions,
  Autocomplete,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BadgeIcon from "@mui/icons-material/Badge";
import FancyTooltip from "../components/utils/FancyTooltip";
import Loading from "../components/utils/Loading";
import { useApp } from "../hooks/useApp";
import ResourceAccess from "../components/security/ResourceAccess";
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { formSchemaOptionsName } from "../types/Utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { getAreas } from "../services/area/AreaService";
import { getOffices } from "../services/office/OfficeService";
import { getEndUsers } from "../services/endUser/EndUserService";
import { getCategories } from "../services/category/CategoryService";
import moment from "moment";
import { createActivo } from "../services/activo/ActivoService";
import { properties } from "../utils/Properties_es";
import { getProviders } from "../services/provider/ProviderService";
import { ContentArea } from "../types/Area";
import { ContentOffice } from "../types/Office";
import { ContentEndUser } from "../types/EndUser";
import { ContentCategory } from "../types/Category";
import { ContentProvider } from "../types/Provider";
import { ContentAssets } from "../types/Activo";
import { getActivoOutstandingById, updateActivoOutstanding } from "../services/activo/ActivoOutstanding";
import { validationNumberNegative } from "../utils/Validation";



const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));



const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "inherit",
  ...(ownerState.active && {
    backgroundColor: `${theme.palette.secondary.dark}`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.35)",
  }),
  ...(ownerState.completed && {
    backgroundColor: `${theme.palette.secondary.dark}`,
  }),
}));


function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentIndIcon sx={{ fontSize: 25 }} />,
    2: <BadgeIcon sx={{ fontSize: 25 }} />,
    3: <PeopleAltIcon sx={{ fontSize: 25 }} />,
    4: <PeopleAltIcon sx={{ fontSize: 25 }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    // sx={{ cursor: "pointer" }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

interface StepsType {
  label: string;
}

const steps: StepsType[] = [
  {
    label: "Paso 1",
  },

  {
    label: "Paso 2",
  },
  {
    label: "Creación de activos",
  },
];



const validationStep2 = Yup.object().shape({
  amountBuy: Yup.number().when('leasing', (leasing, schema) => {
    if (!leasing) {
      return schema.required('Campo es requerido').min(1, 'Debe ser mayor a 0');
    } else {
      return schema;
    }
  }),
  initialBalance: Yup.number().when('leasing', (leasing, schema) => {
    if (!leasing) {
      return schema.required('Campo es requerido').min(1, 'Debe ser mayor a 0');
    } else {
      return schema;
    }
  }),
  anual: Yup.number().when('leasing', (leasing, schema) => {
    if (!leasing) {
      return schema.required('Campo es requerido').min(1, 'Debe ser mayor a 0');
    } else {
      return 
    }
  }),
  // buyDate: Yup.string().required('Campo es requerido'),
  expirationDate: Yup.string().when('leasing', (leasing, schema) => {
    if (!leasing) {
      return schema.required('Campo es requerido');
    } else {
      return schema;
    }
  }),
  depreciationYear: Yup.number().when('leasing', (leasing, schema) => {
    if (!leasing) {
      return schema.required('Campo es requerido').min(1, 'Debe ser mayor a 0');
    } else {
      return schema
    }
  }),
});


interface FormCreateActivo {
  description: string;
  category: {
    id: number;
  };
  office: {
    id: number | string;
    name?: string;
  } | null;
  area: {
    id: number | string;
    name?: string;
  } | null;
  finalUser: {
    id: number | string;
    name?: string;
    lastName?: string;
  } | null;
  serie: string;
  provider: {
    id: number;

  };
  depreciationYear: number;
  anual: number;
  buyDate: string;
  expirationDate: string;
  amountBuy: number;
  initialBalance: number;
  requiredSerie: boolean;
  requiredOfficeAreaUserFinal: boolean;
  warrantyDate: string;
  ncf: string;
  leasing: boolean;
}

const StepsActivosApprove = () => {
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    setSuccessMsg,

  } = useApp();

  const theme = useTheme();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [allAreas, setAllAreas] = useState<ContentArea[]>([]);
  const [allOffices, setAllOffices] = useState<ContentOffice[]>([]);
  const [allEndUsers, setAllEndUsers] = useState<ContentEndUser[]>([]);
  const [allCategories, setAllCategories] = useState<ContentCategory[]>([]);
  const [allProviders, setAllProviders] = useState<ContentProvider[]>([]);
  const [officeSelected, setOfficeSelected] = useState<ContentOffice | null>(null);
  const [dataActivo, setDataActivo] = useState<ContentAssets | null>(null);
  const [loadedData, setLoadedData] = useState<boolean>(false);
  const { id } = useParams();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [dataActivoToCreate, setDataActivoToCreate] = useState<ContentAssets | null>(null);

  const validationStep1 = Yup.object().shape({
    description: Yup.string().required('Campo es requerido').max(100, 'Máximo 100 caracteres'),
    serie: Yup.string().when('provider', (provider, schema) => {
      if (!watchRequiredSerie) {
        return schema.required('Campo es requerido').max(50, 'Máximo 50 caracteres');
      } else {
        return schema.max(50, 'Máximo 50 caracteres');
      }
    }),
    provider: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    category: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    office: Yup.object().when('requiredOfficeAreaUserFinal', {
      is: (requiredOfficeAreaUserFinal: boolean) => !requiredOfficeAreaUserFinal,
      then: Yup.object().shape(formSchemaOptionsName).typeError('Campo es requerido'),
      otherwise: Yup.object().notRequired(),
    }),
    area: Yup.object().when('requiredOfficeAreaUserFinal', {
      is: (requiredOfficeAreaUserFinal: boolean) => !requiredOfficeAreaUserFinal,
      then: Yup.object().shape(formSchemaOptionsName).typeError('Campo es requerido'),
      otherwise: Yup.object().notRequired(),
    }),
    finalUser: Yup.object().when('requiredOfficeAreaUserFinal', {
      is: (requiredOfficeAreaUserFinal: boolean) => !requiredOfficeAreaUserFinal,
      then: Yup.object().shape(formSchemaOptionsName).typeError('Campo es requerido'),
      otherwise: Yup.object().notRequired(),
    }),

  });

  const getValidationSchema = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return validationStep1;
      case 1:
        return validationStep2;
      default:
        return validationStep1;
    }
  };

  const validation = getValidationSchema(activeStep);


  function getCurrentDate() {
    return moment().format('YYYY-MM-DD');
  }

  function parseNumber(number: any) {
    if (typeof number === 'string') {
      const parsedNumber = number.replace(',', '.');

      const parsedValue = Number(parsedNumber);
      if (!isNaN(parsedValue)) {
        return parsedValue;
      }
    }

    return number;
  }

  const defaultValues = {
    description: '',
    serie: '',
    depreciationYear: 0,
    anual: 0,
    buyDate: '',
    expirationDate: '',
    amountBuy: 0,
    initialBalance: 0,
    requiredSerie: false,
    requiredOfficeAreaUserFinal: false,
    leasing: false,
    warrantyDate: '',
    ncf: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm<FormCreateActivo>({
    // @ts-ignore
    defaultValues,
    resolver: yupResolver(validation),
    mode: 'onChange',
  });


  const fetchInputs = async () => {
    setLoading && setLoading(true);
    try {
      const results = await Promise.all([
        getAreas(0, 1000),
        getOffices(0, 1000),
        getEndUsers(0, 1000),
        getCategories(0, 1000),
        getProviders(0, 1000),
      ]);

      setAllAreas(results[0].content);
      setAllOffices(results[1].content);
      setAllEndUsers(results[2].content);
      setAllCategories(results[3].content);
      setAllProviders(results[4].content);
      setLoading && setLoading(false);
      setLoadedData(true);


    } catch (error) {
      console.log(error);
    }
  };


  const obtenerActivo = async (id: number) => {
    try {

      const data = await getActivoOutstandingById(id);
      setDataActivoToCreate(data.content);
      setValue('description', data.content.description);
      // @ts-ignore
      setValue('category', allCategories.find((c) => c.id === data.content.category));
      // @ts-ignore
      setValue('office', allOffices.find((c) => c.id === data.content.office));
      // @ts-ignore
      setValue('area', allAreas.find((c) => c.id === data.content.area));
      // @ts-ignore
      setValue('finalUser', allEndUsers.find((c) => c.id === data.content.finalUser));
      // @ts-ignore
      setValue('provider', allProviders.find((c) => c.id === data.content.provider));
      setValue('serie', data.content.serie);
      setValue('buyDate', moment(data.content.buyDate).format('YYYY-MM-DD'));
      setValue('expirationDate', moment(data.content.expirationDate).format('YYYY-MM-DD'));
      setValue('amountBuy', data.content.amountBuy);
      setValue('initialBalance', data.content.initialBalance);
      setValue('requiredSerie', !!!data.content.serie);
      setValue('requiredOfficeAreaUserFinal', !!!data.content.office);
      setValue('warrantyDate', moment(data.content.warrantyDate).format('YYYY-MM-DD'));
      setValue('ncf', data.content.ncf);
      setValue('leasing', data.content.scheme === "leasing" ? true : false);
      setDataActivo(data.content);

    } catch (error) {
      console.log(error);
    }
  };

  function obtenerAreaPorOficina(officeId: number) {
    const areasPorOficina = allAreas.filter((area) => area.office === officeId);
    return areasPorOficina;
  }

  useEffect(() => {
    fetchInputs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && loadedData) {
      obtenerActivo(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedData]);


  const onSubmit = async (data: any) => {
    if (activeStep <= 1) {
      setActiveStep(activeStep + 1);
      return;
    }

    setDisabledButton(true)

    const inputData = {
      ...data,
      anual: watchLeasing ? null : data.anual,
      serie: watchRequiredSerie ? '' : data.serie,
      id: id ? Number(id) : 0,
      description: data.description,
      depreciationYear: data.depreciationYear === '' ? null : Number(data.depreciationYear),
      expirationDate: watchLeasing ? null : data.expirationDate,
      warrantyDate: watchLeasing ? null : data.warrantyDate === "Invalid date" ? null : data.warrantyDate,
      ncf: watchLeasing ? null : data.ncf,
      scheme: watchLeasing ? "leasing" : "activoPropio",
      buyDate: watchLeasing || !!!data.buyDate ? null : data.buyDate,
      area: watchRequiredOfficeAreaUserFinal ? null : Number(data.area.id),
      office: watchRequiredOfficeAreaUserFinal ? null : Number(data.office.id),
      finalUser: watchRequiredOfficeAreaUserFinal ? null : Number(data.finalUser.id),
      provider: Number(data.provider.id),
      category: Number(data.category.id),
      amountBuy: watchLeasing ? null : parseNumber(data.amountBuy),
      initialBalance: watchLeasing ? null : parseNumber(data.initialBalance),
      status: watchExpirationDate ? watchExpirationDate > getCurrentDate() ? "Activo vigente" : "Activo Depreciado" : null,
    }
    try {

      let createData = await createActivo({
        ...inputData, createUser: authInfo ? authInfo.name : 'probando',
      });

      await updateActivoOutstanding({
        ...dataActivoToCreate, approval: "Aprobado", updateUser: authInfo ? authInfo.name : 'probando', assets: createData.id
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_enduser_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      navigate('/formularioActivos');
      //call fetch data
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }

  };



  type FieldName = keyof FormCreateActivo;



  const watchValorCompra = watch('amountBuy');
  const watchBuyDate = watch('buyDate');
  const watchWarrantyDate = watch('warrantyDate');
  const watchExpirationDate = watch('expirationDate');
  const watchRequiredSerie = watch('requiredSerie');
  const watchRequiredOfficeAreaUserFinal = watch('requiredOfficeAreaUserFinal');
  const watchOffice = watch('office');
  const watchLeasing = watch('leasing');
  const watchDepreciationYear = watch('depreciationYear');

  useEffect(() => {
    if ((watchOffice?.id !== dataActivo?.office) && !watchRequiredOfficeAreaUserFinal) {
      setValue('area', null);
      // @ts-ignore
      setDataActivo({ ...dataActivo, office: watchOffice?.id as any })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchOffice])

  const setValueToInitialBalance = () => {
    setValue('initialBalance', watchValorCompra);
  }

  useEffect(() => {
    setValueToInitialBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValorCompra]);



  useEffect(() => {
    if (watchRequiredSerie) {
      setValue('serie', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRequiredSerie])

  useEffect(() => {
    if (watchLeasing) {
      setValue('amountBuy', 0);
      setValue('initialBalance', 0);
      setValue('expirationDate', '')
      setValue('warrantyDate', '');
      setValue('ncf', '');
      setValue('depreciationYear', 0)
      setValue('anual', 0)
      setValue('buyDate', '')
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [watchLeasing])

  const dateToExpire = () => {
    if (watchBuyDate && watchDepreciationYear) {
      const expirationDate = moment(watchBuyDate).add(watchDepreciationYear, 'years');
      return expirationDate.format('YYYY-MM-DD');
    };
    return getCurrentDate();
  };

  const rejectChangeUser = async () => {
    setLoading && setLoading(true);
    const data = {
      ...dataActivoToCreate,
      approval: "Rechazado",
      updateUser: authInfo ? authInfo.name : "Usuario de Prueba",
    }

    try {

      await updateActivoOutstanding(data)
      setSuccessMsg && setSuccessMsg("Solicitud rechazada");
      navigate('/pendingApproval');
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  }




  useEffect(() => {

    const dataActivoFormat = moment(dataActivo?.buyDate).format('YYYY-MM-DD')

    if (watchBuyDate && watchDepreciationYear && (!(watchBuyDate === dataActivoFormat) || !(watchDepreciationYear === Number(dataActivo?.depreciationYear))) && !watchLeasing && id) {
      setValue('expirationDate', dateToExpire());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepreciationYear, watchBuyDate]);

  useEffect(() => {
    if (watchBuyDate && watchDepreciationYear && !id) {
      setValue('expirationDate', dateToExpire());
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepreciationYear, watchBuyDate]);

  useEffect(() => {
    if (watchRequiredOfficeAreaUserFinal) {
      setValue('office', {
        id: 0,
        name: "No asignado"
      });
      setValue('area', {
        id: 0,
        name: "No asignado"
      });
      setValue('finalUser', {
        id: 0,
        name: "Pendiente por asignar",
        lastName: ""
      });
    } else {
      setValue('office', null);
      setValue('area', null);
      setValue('finalUser', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRequiredOfficeAreaUserFinal])





  const onCancel = () => {
    if (activeStep === 0) {
      navigate('/pendingApproval');
    }
    setActiveStep(activeStep - 1);
  };

  function generateTextField(name: FieldName, label: string,) {
    return (
      <Grid item xs={12} lg={4}>
        <TextField
          fullWidth
          variant='standard'
          disabled
          label={label}
          sx={{ '& input': { pl: '0!important', color: 'black !important' } }}
          {...register(name)}
        />
      </Grid>
    );
  }

  const generateMultiSelect = (name: FieldName, label: string, options: any[]) => {
    return (
      <Grid item xs={12} lg={4}>
        <Controller
          control={control}
          name={name}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              options={options}
              disabled
              getOptionLabel={(option: any) => `${option.name} ${!!option.lastName ? option.lastName : ""}`}
              isOptionEqualToValue={(option: any, value: any) =>
                option.id === value.id
              }
              //@ts-ignore
              value={value}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    label={label}
                    variant="standard"
                    sx={{ '& input': { pl: '0!important', color: 'black !important' } }}
                    disabled
                  />
                );
              }}
            />
          )}
        />
      </Grid>
    )
  }

  return (
    <>
      {isLoading && <Loading />}
      <Box
        sx={{ px: 3.75, pt: 12, pb: 12, mr: "auto", ml: "auto" }}
        maxWidth="lg"
      >
        <Paper
          sx={{
            padding: {
              xs: `${theme.spacing(10)} ${theme.spacing(5)}`,
              md: theme.spacing(10),
            },
            borderRadius: theme.spacing(4),
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            className={`${activeStep}`}
          >
            {steps.map((step, i) => (
              <Step key={i}>
                {
                  //@ts-ignore
                  ResourceAccess(true, "CHANGESTAGE:WRITE") === false ? (
                    <FancyTooltip
                      title={
                        i === 0 &&
                        "Usuario no tiene permisos de ver los otros pasos"
                      }
                      placement="top"
                    >
                      <StepLabel
                        StepIconComponent={ColorlibStepIcon}
                        sx={{
                          "& .MuiStepLabel-label": {
                            color: `${theme.palette.greyDue.light}`,
                            fontFamily: "D-dinExp",
                            fontWeight: 700,
                            fontSize: "15px",
                          },
                        }}
                      >
                        {step.label}
                      </StepLabel>
                    </FancyTooltip>
                  ) : (
                    <StepLabel
                      StepIconComponent={ColorlibStepIcon}
                      sx={{
                        "& .MuiStepLabel-label": {
                          color: `${theme.palette.greyDue.light}`,
                          fontFamily: "D-dinExp",
                          fontWeight: 700,
                          fontSize: "15px",

                        },
                      }}
                    >
                      {step.label}
                    </StepLabel>
                  )}
              </Step>
            ))}
          </Stepper>
          <>
            <Box
              sx={{ px: 3.75, pt: 12, pb: 0, mr: "auto", ml: "auto" }}
              maxWidth="lg"
            >

              {activeStep !== 2 && (

                <Controller
                  control={control}
                  defaultValue={false}
                  name="leasing"

                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            marginLeft: '10px',
                          }}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Leasing"
                    />
                  )}
                />
              )}

              <Paper
                sx={{
                  padding: {
                    xs: `${theme.spacing(10)} ${theme.spacing(5)}`,
                    md: theme.spacing(10),
                  },
                  pb: theme.spacing(2),
                  borderRadius: theme.spacing(4),
                }}
              >

                <form>
                  {activeStep === 0 && (
                    <Grid container spacing={8} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
                      <Grid item xs={12} lg={4.5}>
                        <Controller
                          name={'description'}
                          control={control}
                          defaultValue={dataActivo?.description}

                          render={({ field: { onChange, value } }) => (
                            <TextField
                              inputProps={{
                                maxLength: 100,
                              }}
                              fullWidth
                              size='small'
                              label='Descripción'
                              type='text'
                              variant='standard'
                              value={value}
                              autoComplete='description'
                              sx={{ '& input': { pl: '0!important' } }}
                              {...register('description')}
                              error={errors.description && Boolean(errors.description)}
                              helperText={errors.description && errors.description.message}
                            />
                          )}
                        />
                      </Grid>

                      {/* {!watchRequiredSerie && ( */}
                      <Grid item xs={12} lg={3.5}>
                        <Controller
                          name={'serie'}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              disabled={watchRequiredSerie}
                              inputProps={{
                                maxLength: 50,
                              }}
                              size='small'
                              label='Serie'
                              type='text'
                              variant='standard'
                              value={value}

                              autoComplete='serie'
                              sx={{ '& input': { pl: '0!important' } }}
                              {...register('serie')}
                              error={errors.serie && !watchRequiredSerie
                                && Boolean(errors.serie)}
                              helperText={errors.serie && !watchRequiredSerie &&
                                errors.serie.message}
                            />
                          )}
                        />
                      </Grid>
                      {/* )} */}
                      <Grid item xs={12} lg={2} alignSelf="center" style={{
                        marginTop: '14px',
                      }}>
                        <Controller
                          control={control}
                          defaultValue={false}
                          name="requiredSerie"
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  color="primary"
                                />
                              }
                              label="Activo no tiene serie"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2} alignSelf="center" style={{
                        marginTop: '14px',
                      }}>
                        <Controller
                          control={control}
                          defaultValue={false}
                          name="requiredOfficeAreaUserFinal"
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  color="primary"
                                />
                              }
                              label="Pendiente de asignar"
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <Controller
                          control={control}
                          name="provider"
                          defaultValue={{ id: dataActivo?.provider || 0 }}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, newValue: any) => {
                                onChange(newValue);
                              }}
                              options={allProviders}
                              getOptionLabel={(option: any) => option.name || ""}
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              //@ts-ignore
                              value={value}
                              renderInput={(params) => {
                                const inputProps = params.inputProps;
                                inputProps.autoComplete = "new-password";
                                return (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Proveedor"
                                    variant="standard"
                                    sx={{ "& input": { pl: "0!important" } }}
                                    error={!!errors.provider}
                                    helperText={errors.provider && errors.provider.message}
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <Controller
                          control={control}
                          name="category"
                          defaultValue={{ id: dataActivo?.category || 0 }}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, newValue: any) => {
                                onChange(newValue);
                              }}
                              options={allCategories}
                              getOptionLabel={(option: any) => option.name || ""}
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              //@ts-ignore
                              value={value}
                              renderInput={(params) => {
                                const inputProps = params.inputProps;
                                inputProps.autoComplete = "new-password";
                                return (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Categoría"
                                    variant="standard"
                                    sx={{ "& input": { pl: "0!important" } }}
                                    error={!!errors.category}
                                    helperText={errors.category && errors.category.message}
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Controller
                          control={control}
                          // @ts-ignore 
                          defaultValue={{ id: dataActivo?.office || 0 }}
                          name="office"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, newValue: any) => {
                                onChange(newValue);
                                setOfficeSelected(newValue)
                              }}
                              options={allOffices}
                              getOptionLabel={(option: any) => option.name || ""}
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              //@ts-ignore
                              value={value}
                              disabled={watchRequiredOfficeAreaUserFinal}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Oficina"
                                    variant="standard"
                                    sx={{ "& input": { pl: "0!important" } }}
                                    error={!!errors.office && !watchRequiredOfficeAreaUserFinal}
                                    helperText={!watchRequiredOfficeAreaUserFinal && errors.office && errors.office.message}
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <Controller
                          control={control}
                          // @ts-ignore 
                          defaultValue={{ id: dataActivo?.area || 0 }}
                          name="area"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, newValue: any) => {
                                onChange(newValue);
                              }}
                              options={obtenerAreaPorOficina(officeSelected?.id || 0)}
                              getOptionLabel={(option: any) => option.name || ""}
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              //@ts-ignore
                              value={value}
                              disabled={watchRequiredOfficeAreaUserFinal}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Area"
                                    variant="standard"
                                    sx={{ "& input": { pl: "0!important" } }}
                                    error={!!errors.area && !watchRequiredOfficeAreaUserFinal}
                                    helperText={errors.area && !watchRequiredOfficeAreaUserFinal && errors.area.message}
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <Controller
                          control={control}
                          // @ts-ignore 
                          defaultValue={{ id: dataActivo?.area || 0 }}
                          name="finalUser"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, newValue: any) => {
                                onChange(newValue);
                              }}
                              options={allEndUsers}
                              getOptionLabel={(option: any) => `${option.name} ${option.lastName}` || ""}
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              //@ts-ignore
                              value={value}
                              disabled={watchRequiredOfficeAreaUserFinal}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Usuario final"
                                    variant="standard"
                                    sx={{ "& input": { pl: "0!important" } }}
                                    error={!!errors.finalUser && !watchRequiredOfficeAreaUserFinal}
                                    helperText={errors.finalUser && !watchRequiredOfficeAreaUserFinal && errors.finalUser.message}
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                  )}
                  {activeStep === 1 && (
                    <Grid container spacing={4} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
                      <Grid item xs={12} lg={4}>
                        <Controller
                          name={'buyDate'}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              inputProps={{
                                max: moment().format('YYYY-MM-DD'),
                              }}
                              size='small'
                              label='Fecha de compra'
                              type='date'
                              disabled={watchLeasing}
                              variant='standard'
                              value={value}
                              sx={{
                                '& label': {
                                  marginTop: value ? "0px" : '-20px'
                                },
                                '& label.Mui-focused': {
                                  // color: 'black',
                                  marginTop: '0px'
                                },
                                '& input': {
                                  pl: '0!important'
                                }
                              }}
                              {...register('buyDate')}
                              error={errors.buyDate && Boolean(errors.buyDate)}
                              helperText={errors.buyDate && errors.buyDate.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Controller
                          name={'depreciationYear'}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              size='small'
                              disabled={watchLeasing}
                              inputProps={{
                                max: 100,
                              }}
                              label='Nro. Años de Vida Util'
                              type='number'
                              variant='standard'
                              value={value}
                              autoComplete='depreciationYear'
                              sx={{ '& input': { pl: '0!important' } }}
                              {...register('depreciationYear')}
                              error={errors.depreciationYear && Boolean(errors.depreciationYear)}
                              helperText={errors.depreciationYear && errors.depreciationYear.message}
                              onChange={e => validationNumberNegative(e, onChange)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Controller
                          name={'expirationDate'}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              size='small'
                              disabled={watchLeasing}
                              label='Fecha de vencimiento'
                              type='date'
                              variant='standard'
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                '& label.Mui-focused': {
                                  // color: 'black',
                                  marginTop: '0px'
                                },
                                '& input': {
                                  pl: '0!important'
                                }
                              }}
                              {...register('expirationDate')}
                              error={!watchExpirationDate && errors.expirationDate && Boolean(errors.expirationDate)}
                              helperText={(watchExpirationDate && watchExpirationDate < getCurrentDate()) ? "Activo ya depreciado" : !!watchExpirationDate ? '' : (errors.expirationDate && errors.expirationDate.message)}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <Controller
                          name={'warrantyDate'}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              size='small'
                              label='Fecha de Garantía'
                              type='date'
                              value={value}
                              disabled={watchLeasing}
                              inputProps={{
                                min: moment().format('YYYY-MM-DD'),
                              }}
                              variant='standard'
                              sx={{
                                '& label': {
                                  marginTop: value ? "0px" : '-20px'
                                },
                                '& label.Mui-focused': {
                                  // color: 'black',
                                  marginTop: '0px'
                                },
                                '& input': {
                                  pl: '0!important'
                                }
                              }}
                              {...register('warrantyDate')}
                              error={errors.warrantyDate && Boolean(errors.warrantyDate)}
                              helperText={errors.warrantyDate && errors.warrantyDate.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Controller
                          name={'ncf'}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              inputProps={{
                                maxLength: 50,
                              }}
                              disabled={watchLeasing}
                              size='small'
                              label='Numero de Factura (NCF)'
                              type='text'
                              variant='standard'
                              value={value}

                              autoComplete='ncf'
                              sx={{ '& input': { pl: '0!important' } }}
                              {...register('ncf')}
                              error={errors.ncf && Boolean(errors.ncf)}
                              helperText={errors.ncf &&
                                errors.ncf.message}
                            />
                          )}
                        />
                      </Grid>




                      <Grid item xs={12} lg={4}>
                        <Controller
                          name='anual'
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              // onChange={onChange}
                              size='small'
                              label='Porcentaje Anual'
                              disabled={watchLeasing}
                              inputProps={{
                                maxLenght: 10
                              }}
                              type='number'
                              variant='standard'
                              value={value}
                              autoComplete='anual'
                              {...register('anual',
                              )}
                              InputProps={{
                                endAdornment: <InputAdornment sx={{ '& p': { color: "black" } }} position='end'>%</InputAdornment>,
                              }}
                              sx={{ color: 'red', '& input': { pl: '0!important' } }}
                              error={errors.anual && Boolean(errors.anual)}
                              helperText={errors.anual && errors.anual.message}
                              onChange={e => validationNumberNegative(e, onChange)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Controller
                          name={'amountBuy'}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              disabled={watchLeasing}
                              size='small'
                              label='Valor de compra DOP'
                              type='number'
                              variant='standard'
                              value={value}
                              autoComplete='amountBuy'
                              sx={{ '& input': { pl: '0!important' } }}
                              {...register('amountBuy')}
                              error={errors.amountBuy && Boolean(errors.amountBuy)}
                              helperText={errors.amountBuy && errors.amountBuy.message}
                              onChange={(e) => {
                                const newValue = e.target.value;
                              
                                // Si el valor comienza con 0 y no es igual a "0" o "0." (para permitir decimales)
                                if (newValue.startsWith('0') && newValue !== '0' && !newValue.startsWith('0.')) {
                                  // Reemplaza el valor con lo que el usuario ha ingresado sin el 0 inicial
                                  onChange(newValue.replace(/^0+/, ''));
                                } else if (newValue === '0' || /^-?\d*\.?\d*$/.test(newValue)) {
                                  const parsedValue = parseFloat(newValue);
                                  if (!isNaN(parsedValue) && parsedValue >= 0) {
                                    onChange(parsedValue);
                                  }
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Controller
                          name={'initialBalance'}
                          control={control}

                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              size='small'
                              label='Balance inicial DOP'
                              type='number'
                              variant='standard'
                              disabled={watchLeasing}
                              value={value}
                              autoComplete='initialBalance'
                              sx={{ '& input': { pl: '0!important' } }}
                              {...register('initialBalance')}
                              error={errors.initialBalance && Boolean(errors.initialBalance)}
                              helperText={errors.initialBalance && errors.initialBalance.message}
                              onChange={(e) => {
                                const newValue = e.target.value;
                              
                                // Si el valor comienza con 0 y no es igual a "0" o "0." (para permitir decimales)
                                if (newValue.startsWith('0') && newValue !== '0' && !newValue.startsWith('0.')) {
                                  // Reemplaza el valor con lo que el usuario ha ingresado sin el 0 inicial
                                  onChange(newValue.replace(/^0+/, ''));
                                } else if (newValue === '0' || /^-?\d*\.?\d*$/.test(newValue)) {
                                  const parsedValue = parseFloat(newValue);
                                  if (!isNaN(parsedValue) && parsedValue >= 0) {
                                    onChange(parsedValue);
                                  }
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                  )}
                  {activeStep === 2 && (
                    <Grid container spacing={8} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
                      {generateTextField('description', 'Descripción')}
                      {!watchRequiredSerie && generateTextField('serie', 'Serie')}
                      {generateMultiSelect('provider', 'Proveedor', allProviders)}
                      {generateMultiSelect('category', 'Categoría', allCategories)}
                      {generateMultiSelect('office', 'Oficina', allOffices)}
                      {generateMultiSelect('area', 'Area', allAreas)}
                      {generateMultiSelect('finalUser', 'Usuario final', allEndUsers)}
                      {!watchLeasing && generateTextField('depreciationYear', 'Nro. Años de Vida Util')}
                      {!watchLeasing && generateTextField('anual', 'Porcentaje Anual')}
                      {(watchBuyDate || watchBuyDate === "Invalid date") && (

                        <Grid item xs={12} lg={4}>
                          <Controller
                            name={'buyDate'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                disabled
                                size='small'
                                label='Fecha de compra'
                                type='date'
                                name="buyDate"
                                variant='standard'
                                value={value}
                                sx={{ '& input': { pl: '0!important', color: 'black !important' } }}
                              />
                            )}
                          />
                        </Grid>
                      )}

                      {!watchLeasing && (

                        <Grid item xs={12} lg={4}>
                          <Controller
                            name={'expirationDate'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size='small'
                                label='Fecha de vencimiento'
                                type='date'
                                value={value}
                                variant='standard'
                                sx={{ '& input': { pl: '0!important', color: 'black !important' } }}
                                disabled
                                FormHelperTextProps={{
                                  sx: { color: 'black !important' },
                                }}
                                helperText={(watchExpirationDate && watchExpirationDate < getCurrentDate()) ? "Activo ya depreciado" : ""}

                              />
                            )}
                          />
                        </Grid>
                      )}

                      {(watchWarrantyDate || watchWarrantyDate === "Invalid date") && (

                        <Grid item xs={12} lg={4}>
                          <Controller
                            name={'warrantyDate'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                disabled
                                size='small'
                                label='Fecha de Garantía'
                                type='date'
                                name="warrantyDate"
                                variant='standard'
                                value={value}
                                sx={{ '& input': { pl: '0!important', color: 'black !important' } }}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {(watch("ncf") !== "") && generateTextField('ncf', 'Numero de Factura (NCF)')}
                      {!watchLeasing && generateTextField('amountBuy', 'Valor de compra')}
                      {!watchLeasing && generateTextField('initialBalance', 'Balance inicial')}
                    </Grid>
                  )}


                </form>
              </Paper>
              <Actions sx={{ pb: 4, px: 4, display: "flex", justifyContent: "space-between" }}>

                <Button
                  variant='contained'
                  color='error'
                  sx={{
                    mt: 2,
                    mr: 2,
                    height: '32px',

                    // hover
                    '&:hover': {
                      backgroundColor: '#e53935',
                      boxShadow: 'none',
                    },
                  }}
                  disabled={disabledButton}
                  onClick={rejectChangeUser}>
                  Rechazar
                </Button>
                <Box>
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{
                      mt: 2,
                      mr: 2,
                    }}
                    disabled={disabledButton}
                    onClick={handleSubmit(onSubmit)}>
                    {activeStep === 2 ? 'Aprobar' : 'Siguiente'}
                  </Button>

                  <Button
                    variant='contained'
                    color='secondary'
                    sx={{
                      mt: 2,
                      mr: 2,
                    }}
                    onClick={onCancel}
                    autoFocus>
                    {activeStep === 0 ? 'Cancelar' : 'Atrás'}
                  </Button>{' '}
                </Box>

              </Actions>
            </Box>
          </>
        </Paper>
      </Box>
    </>
  );
};

export default StepsActivosApprove;
