import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  useTheme,
  TextField,
  Autocomplete,
  InputLabel,
  Button,
  Stack,
  Divider,
  Dialog,
} from "@mui/material";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import FancyPaper from "../components/FancyPaper";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { useForm } from "react-hook-form";
import { getRoles } from "../services/role/RoleService";
import { getRequestAllDocuments } from "../services/documents/DocumentService";
import {
  getRequestDocumentRole,
  createRequest,
  updateRequest,
  deleteRequest,
  findByIdRequest,
} from "../services/documents/DocumentsRoleService";
import { properties } from "../utils/Properties_es";
import { useApp } from "../hooks/useApp";
import AddOrUpdateDocumentRole from "../components/utils/AddOrUpdateDocumentRole";
import DeleteModal from "../components/utils/DeleteModal";
import ResourceAccess from "../components/security/ResourceAccess";

const DocumentsRoleManagement = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const navigate = useNavigate();
  const [documentRoleData, setDocumentRoleData] = useState<any>([]);
  const [documentRoleDataFilter, setDocumentRoleDataFilter] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const initForm = {
    id: 0,
    role: "",
    email: "",
    personType: "",
    risk: "",
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit, formState } = useForm();
  const [userRole, setUserRole] = useState<any>([]);
  const [documentListData, setDocumentListData] = useState<any>([]);
  const [role, setRole] = useState<any>(null);
  const [page, setPage] = useState(0);
  let filterDocumentRole: any = [];
  let rowData: any = [];

  useEffect(() => {
    let dataInit;
    let dataRole;

    dataInit = async () => {
      await handleFetchData("", 0);
    };
    dataInit();

    if (userRole.length < 1) {
      dataRole = async () => {
        await handleFetchRole();
      };
      dataRole();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let dataInit;
    if (role) {
      dataInit = async () => {
        await handleFetchData(role.id, 0);
      };
    } else {
      dataInit = async () => {
        await handleFetchData("", 0);
      };
    }
    dataInit();
  }, [role]);

  const handleFetchRole = async () => {
    let userRoleData = await getRoles();
    if (userRoleData) {
      setUserRole(userRoleData);
    }

    let documentList = await getRequestAllDocuments(0, "");
    if (documentList) {
      setDocumentListData(documentList);
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(preFilter, 0);
    }
  };

  const handleFetchData = async (filter: string, currentPage: number) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequestDocumentRole(filter, currentPage);
      if (data) {
        setDocumentRoleData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(preFilter, customPage);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let documentRoleDataID = await findByIdRequest(id);
      if (documentRoleDataID) {
        //@ts-ignore
        setFormData(documentRoleDataID.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
      });

      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_emailrole_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      setPage(0);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_documentRole_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      setPage(0);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_documentRole_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);
      //reset page and call fetch data
      setPage(0);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Validate errors in case of opening modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      await handleFetchByID(id);
    }

    if (modalAction === "delete") {
      object = documentRoleData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Documentos por perfil">
        <Grid container spacing={8} sx={{ pb: 4 }}>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel>Perfiles</InputLabel>
            <Autocomplete
              onChange={(event: any, newValue: string | null) => {
                setRole(newValue);
              }}
              options={userRole}
              getOptionLabel={(option: any) => option.name || ""}
              isOptionEqualToValue={(option: any, value: any) =>
                option.id === value.id
              }
              renderInput={(params) => {
                const inputProps = params.inputProps;
                inputProps.autoComplete = "new-password";
                return (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    sx={{ "& input": { pl: "0!important" } }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={8} sx={{ pt: 8, pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"DOCUMENTSROLE:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar documento por perfil
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Rol</TableCell>
                <TableCell align="center">ID Documento</TableCell>
                <TableCell align="center">Nombre Documento</TableCell>
                <TableCell align="center">Tipo de Persona</TableCell>
                <TableCell align="center">Nivel de Riesgo</TableCell>
                <TableCell align="center">Requerido</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentRoleData &&
                documentRoleData?.content?.length > 0 &&
                documentRoleData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.role.name}</TableCell>
                    <TableCell align="center">{row.document.id}</TableCell>
                    <TableCell align="center">{row.document.name}</TableCell>
                    <TableCell align="center">
                      {row.personType &&
                        row.personType.replace(/[^A-Z,í]+/gi, " ")}
                    </TableCell>
                    <TableCell align="center">
                      {row.risk && row.risk.replace(/[^A-Z,]+/gi, " ")}
                    </TableCell>
                    <TableCell align="center">
                      {row.required ? `Sí` : `No`}
                    </TableCell>
                    <TableCell align="center">
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"DOCUMENTSROLE:WRITE"}
                      >
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            documentRoleData?.content?.length > 0
              ? documentRoleData?.content?.length
              : 0
          }
          rowsPerPage={documentRoleData?.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={documentRoleData?.totalElements}
          totalPages={documentRoleData?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateDocumentRole
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            roleData={userRole}
            documentListData={documentListData}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.document.name}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default DocumentsRoleManagement;
