import React, { FC, useState } from 'react';
import Excel from 'exceljs';
// @ts-ignore
import { saveAs } from 'file-saver';
import {
  Box,
  Button,
  DialogContent,
  Typography,
  Dialog,
  DialogTitle,
  styled,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getDataToExcel } from '../../services/activo/ActivoService';
import { convertirAMoneda, searchNameContent } from '../../types/Utils';
import { useApp } from '../../hooks/useApp';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs, { Dayjs } from 'dayjs';

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));

const Text = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.greyDue.main,
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  textAlign: 'center',
  fontSize: '17px',
  padding: theme.spacing(4),
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

const columns = [
  { header: 'Codigo', key: 'code', width: 10 },
  { header: 'Descripcion', key: 'description', width: 32 },
  { header: 'Proveedor', key: 'provider', width: 35 },
  { header: 'Categoria', key: 'category', width: 20 },
  { header: 'Oficina', key: 'office', width: 20 },
  { header: 'Area', key: 'area', width: 20 },
  { header: 'Usuario Final', key: 'finalUser', width: 25 },
  { header: 'Serie', key: 'serie', width: 20 },
  { header: 'Vida util', key: 'depreciationYear', width: 20 },
  { header: 'Porcentaje Anual', key: 'anual', width: 20 },
  { header: 'Fecha de Compra', key: 'buyDate', width: 25 },
  { header: 'Fecha de Expiracion', key: 'expirationDate', width: 25 },
  { header: 'Status del activo', key: 'status', width: 25 },
  { header: 'Tipo de Activo', key: 'scheme', width: 20 },
  { header: 'Fecha de Garantia', key: 'warrantyDate', width: 25 },
  { header: 'Numero de Factura (NCF)', key: 'ncf', width: 30 },
  { header: 'Valor de Compra', key: 'amountBuy', width: 20 },
  { header: 'Balance Inicial', key: 'initialBalance', width: 15 },
  { header: 'Depreciación Acumulada', key: 'accumulatedDepreciation', width: 25 },
  { header: 'Valor en libros', key: 'bookValue', width: 15 },
];

const workSheetName = 'Reporte Activos Fijos';
const workBookName = 'Reporte Activos Fijos';

interface IGenerateExcelProps {
  data: any[];
  exportAllData: boolean;
  withFilter?: boolean;
  allCategories: any[];
  allOffices: any[];
  allAreas: any[];
  allfinalUsers: any[];
  allProviders: any[];
}

const GenerateExcel: FC<IGenerateExcelProps> = ({
  data,
  exportAllData,
  withFilter,
  allAreas,
  allCategories,
  allOffices,
  allProviders,
  allfinalUsers,
}) => {
  const workbook = new Excel.Workbook();
  const { setLoading } = useApp();
  const { handleSubmit } = useForm();
  const [openModal, setOpenModal] = useState(false);
  const [date, setDate] = useState<Dayjs>(dayjs());

  const onClose = () => {
    setOpenModal(false);
    setDate(dayjs());
  };

  function getColumnName(index: number): string {
    let columnName = '';
    let dividend = index;
    let modulo: number;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }

    return columnName;
  }
  const saveExcel = async (excelData: any[]) => {
    const time = moment().format('DD/MM/YYYY');
    const timeSimulated = date.format('MM/DD/YYYY');
    try {
      const fileName = workBookName;
      const worksheet = workbook.addWorksheet(workSheetName, {
        headerFooter: { firstHeader: 'Reporte Activos Fijos' },
      });

      worksheet.mergeCells('A1:T1');
      const titleCell = worksheet.getCell('A1');
      worksheet.mergeCells('A2:T2');
      const dateCell = worksheet.getCell('A2');
      dateCell.value =
        time !== timeSimulated
          ? `Fecha de reporte simulada al ${timeSimulated}`
          : `Fecha de reporte: ${time}`;

      dateCell.font = { bold: true, size: 20 }; // Aumentar el tamaño de letra a 14
      dateCell.alignment = { vertical: 'middle', horizontal: 'center' }; // Alinear al centro
      dateCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Color de fondo rojo (código ARGB)
      };

      titleCell.value = 'Parallax Valores Puesto de Bolsa, S.A';

      titleCell.font = { bold: true, size: 20 }; // Aumentar el tamaño de letra a 14
      titleCell.alignment = { vertical: 'middle', horizontal: 'center' }; // Alinear al centro
      titleCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Color de fondo rojo (código ARGB)
      };

      // agregar bold true a la fila 3
      worksheet.getRow(3).font = { bold: true };
      worksheet.getColumn(1).font = { bold: true };

      columns.forEach((column, index) => {
        const columnIndex = index + 1;
        worksheet.getCell(`${getColumnName(columnIndex)}3`).value = column.header;
        worksheet.getColumn(columnIndex).width = column.width;
        worksheet.getColumn(columnIndex).alignment = { horizontal: 'center' };
      });

      excelData.forEach((singleData, index) => {
        const rowData = columns.map(column => singleData[column.key]);
        // @ts-ignore
        worksheet.addRow(rowData, { outlineLevel: 0, style: { row: { outlineLevel: 0 } } });
      });

      worksheet.eachRow({ includeEmpty: false }, row => {
        row.eachCell(cell => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
  };

  const onSubmit = async () => {
    setLoading && setLoading(true);
    const idStrings = data.map((item: any) => `ids=${item.id}`).join('&');

    const dateFormat = date.format('MM/DD/YYYY');
    const dataExcel = await getDataToExcel(
      dateFormat,
      withFilter && exportAllData ? idStrings : !withFilter && exportAllData ? '' : idStrings
    );

    const filteredData = dataExcel.filter((item: any) => {
      return data.some((element: any) => element.id === item.id);
    });

    console.log('filteredData', filteredData)

    const transformData = filteredData.map(item => ({
      ...item,
      category: searchNameContent(item.category, allCategories),
      serie: item.serie ? item.serie : 'N/D',
      office: item.office ? searchNameContent(item.office, allOffices) : 'N/A',
      area: item.area ? searchNameContent(item.area, allAreas) : 'N/A',
      finalUser: item.finalUser ? searchNameContent(item.finalUser, allfinalUsers) : 'Sin asignar',
      provider: searchNameContent(item.provider, allProviders),
      buyDate:
        item.buyDate && item.buyDate !== 'Invalid date'
          ? moment(item.buyDate).format('DD-MM-YYYY')
          : 'N/D',
      expirationDate:
        item.expirationDate && item.expirationDate !== 'Invalid date'
          ? moment(item.expirationDate).format('DD-MM-YYYY')
          : 'N/D',
      warrantyDate:
        // @ts-ignore
        item.warrantyDate && item.warrantyDate !== 'Invalid date'
          ? moment(item.warrantyDate).format('DD-MM-YYYY')
          : 'N/D',
      ncf: item.ncf ? item.ncf : 'N/D',
      amountBuy: convertirAMoneda(item.amountBuy),
      initialBalance: convertirAMoneda(item.initialBalance),
      accumulatedDepreciation: item.accumulatedDepreciation
        ? convertirAMoneda(item.accumulatedDepreciation)
        : item.scheme === 'leasing' ? 'N/D' : '0',
      depreciationYear: item.depreciationYear ? item.depreciationYear : 'N/D',
      anual:
        item.scheme && item.scheme !== 'leasing'
          ? item.anual.includes('%')
            ? item.anual
            : `${item.anual}%`
          : 'N/D',
      scheme: item.scheme ? (item.scheme !== 'leasing' ? 'Activo Propio' : 'Leasing') : 'N/D',
      status: item.status ? item.status : 'N/D',
      bookValue: item.bookValue ? convertirAMoneda(item.bookValue) : item.scheme === 'leasing' ? 'N/D' : '0',
    }));

    saveExcel(transformData);
    setLoading && setLoading(false);
    onClose();
  };

  return (
    <>
      <div>
        <Button variant='contained' onClick={() => setOpenModal(true)} disabled={!!!data.length}>
          Exportar
        </Button>

        <Dialog open={openModal} onClose={onClose} fullWidth>
          <Title
            id='alert-dialog-title'
            sx={{
              pt: 4,
              px: 4,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}>
            <FileDownloadIcon />
            Exportar data de activos fijos
          </Title>
          <DialogContent>
            <Text id='alert-dialog-description'>
              <Box
                sx={{
                  textAlign: 'left',
                  fontSize: '17px',
                  // color: 'black',
                  marginBottom: '2rem',
                }}>
                <Typography fontSize='inherit'>Seleccionar fecha para reporte</Typography>
              </Box>

              {/* <Grid item xs={12} lg={4}> */}
              {/* <Controller
                name={'simulatorDate'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Fecha'
                    type='date'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='standard'
                    value={value}
                    sx={{
                      maxWidth: '300px',

                      '& input': {
                        pl: '0!important',
                      },
                    }}
                    {...register('simulatorDate')}
                  />
                )}
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  disableFuture
                  value={date}
                  onChange={newValue => {
                    // @ts-ignore
                    setDate(newValue);
                  }}
                />
              </LocalizationProvider>
              {/* </Grid> */}
            </Text>
          </DialogContent>
          <Actions sx={{ pb: 4, px: 4 }}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleSubmit(onSubmit)}
              // disabled={!watchSimulatorDate}
            >
              Exportar
            </Button>
            <Button
              variant='contained'
              color='secondary'
              sx={{
                mt: 2,
              }}
              onClick={onClose}
              autoFocus>
              Cerrar
            </Button>{' '}
          </Actions>
        </Dialog>
      </div>
    </>
  );
};

export default GenerateExcel;
