import { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Dialog,
  TextField,
  Box,
} from '@mui/material';
import { TrashIcon, PlusIcon, PenIcon } from '../components/svgicons/SvgIcons';
import FancyTooltip from '../components/utils/FancyTooltip';
import FancyPaper from '../components/FancyPaper';
import { Controller, useForm } from 'react-hook-form';
import { properties } from '../utils/Properties_es';
import { useApp } from '../hooks/useApp';
import DeleteModal from '../components/utils/DeleteModal';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import ResourceAccess from '../components/security/ResourceAccess';
import {
  createOffice,
  deleteOffice,
  getOffices,
  updateOffice,
} from '../services/office/OfficeService';
import AddOrUpdateOfficeModal from '../components/utils/AddOrUpdateOffices';
import { BodyOffice, ContentOffice, ModalOffice, OfficeResponse } from '../types/Office';
import Loading from '../components/utils/Loading';

const defaultValues = {
  search: '',
};

const OfficeManagement = () => {
  const { isLoading, setLoading, setErrorMsg, modalData, setModalData, setSuccessMsg, errorMsg } =
    useApp();
  const theme = useTheme();
  const [officesData, setOfficesData] = useState<OfficeResponse>({} as OfficeResponse);
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState(defaultValues);

  const initForm = {
    id: 0,
    name: '',
    description: '',
  };
  const [formData, setFormData] = useState<any>(initForm);

  const { handleSubmit, control, setValue, watch } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };

    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   *
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */

  const handleFetchData = async (currentPage: number, filtro?: string) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage);

      let data = await getOffices(currentPage, 10, filtro);
      if (data) {
        setOfficesData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: BodyOffice) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let createData = await createOffice(data);

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_office_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: ModalOffice) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateOffice(data);

      if (!updateData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_office_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleFilter = async (e: any) => {
    const filtro = e.search;
    setPreFilter({
      search: filtro,
    });

    if (filtro === '') {
      await handleFetchData(0);
    } else {
      await handleFetchData(0, filtro);
    }
  };
  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: number) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteOffice(id);

      if (!deleteData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_office_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any, data?: ContentOffice) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute('data-name');
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');

    if (modalAction === 'update') {
      setFormData(data);
    }

    if (modalAction === 'delete') {
      object = officesData.content.find((p: ContentOffice) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        // @ts-ignore
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== 'delete') {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter.search);
    }
  };

  const onSubmit = async (data: ModalOffice) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAdd(data);
        break;
      case 'update':
        await handleUpdate(data);
        break;
      case 'delete':
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== 'delete') {
      setFormData(formData);
    }
  };

  const handleClearFilter = async (e: any) => {
    await handleFetchData(0, '');
    setValue('search', '');
  };

  const watchSearch = watch('search');

  return (
    <>
      {isLoading && <Loading />}
      {/* <img src={Banner} width="100%" /> */}
      <FancyPaper pagetitle='Oficinas'>
        <Grid container sx={{ pb: 8 }} justifyContent='space-between'>
          <Grid item md={8} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={'OFFICE:WRITE'}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleOpenModal}
                data-name='create'>
                Agregar oficina
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box display='flex' gap={4} justifyContent='flex-end'>
              <Controller
                name='search'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    size='small'
                    sx={{
                      '& label': {
                        marginTop: '-5px',
                      },
                    }}
                  />
                )}
              />

              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(handleFilter)}
                disabled={watchSearch === '' ? true : false}>
                Buscar
              </Button>
              <Button variant='contained' color='primary' onClick={handleClearFilter}>
                Limpiar
              </Button>
            </Box>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>ID</TableCell>
                <TableCell align='center'>Nombre</TableCell>
                <TableCell align='center'>Descripcion</TableCell>
                <TableCell align='center'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {officesData &&
                officesData.content &&
                officesData.content.map((row: ContentOffice, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell component='th' scope='row' align='center'>
                      {row.id}
                    </TableCell>

                    <TableCell align='center'>{row.name}</TableCell>
                    <TableCell align='center'>{row.description}</TableCell>
                    {/* <TableCell align='center'>{getNameArea(row.area)}</TableCell> */}

                    <TableCell align='center'>
                      <ResourceAccess isCode={true} pathOrCode={'OFFICE:WRITE'}>
                        <FancyTooltip title='Editar' placement='top'>
                          <IconButton
                            aria-label='edit'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={e => handleOpenModal(e, row)}
                            data-name='update'
                            data-id={row.id}>
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>

                        <FancyTooltip title='Eliminar' placement='top'>
                          <IconButton
                            aria-label='trash'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name='delete'>
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

        </TableContainer>
          <FancyTablePagination
            count={officesData?.content?.length > 0 ? officesData?.content?.length : 0}
            rowsPerPage={officesData.size}
            page={page}
            onPageChange={handleChangePage}
            totalElements={officesData.totalElements}
            totalPages={officesData.totalPages}
          />
      </FancyPaper>
      {(modalData?.modalType === 'create' || modalData?.modalType === 'update') && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <AddOrUpdateOfficeModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  // @ts-ignore
                  onClick={handleSubmit(onSubmit)}>
                  Aceptar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus>
                  Cancelar
                </Button>{' '}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default OfficeManagement;
