import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
  Navigate,
} from "react-router-dom";
import DashboardHome from "../layouts/DashboardHome";
import UserManagement from "../pages/UserManagement";
// import Reports from "../pages/Reports";
import RoleManagement from "../pages/RoleManagement";
import EmailRoleManagement from "../pages/EmailRoleManagement";

import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import DocumentsRoleManagement from "../pages/DocumentsRoleManagement";
import ActivosManagement from "../pages/ActivosManagement";
import AuthorityManagement from "../pages/AuthorityManagement";
import AuthorityByRoleManagement from "../pages/AuthorityByRoleManagement";
// import ClientManagement from "../pages/ClientManagement";
import Dashboard from "../pages/Dashboard";
import OfficeManagement from "../pages/OfficeManagement";
import AreaManagement from "../pages/AreaManagement";
import ProviderManagement from "../pages/ProviderManagement";
import EndUsersManagement from "../pages/EndUsersManagement";
import CategoryManagement from "../pages/CategoryManagement";
import StepsActivos from "../pages/StepsActivos";
import PendingApprovalManagement from "../pages/PendingApprovalManagement";
import StepsActivosRequest from "../pages/StepsActivosRequest";
import StepsActivosApprove from "../pages/StepsActivosApprove";
import ActivosNoAsignadosManagement from "../pages/ActivosNoAsignadosManagement";
import NotifyManagement from '../pages/NotifyManagement';
const Session = lazy(() => import("../pages/session/Session"));
/** Utils page */
const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/session" element={<Session />} />
      <Route path="/" element={<DashboardHome />}>
        <Route index element={<Dashboard />} />
        <Route path="/userManagement" element={<UserManagement />} />
        <Route path="/roleManagement" element={<RoleManagement />} />
        <Route path="/areaManagement" element={<AreaManagement />} />
        <Route path="/endUserManagement" element={<EndUsersManagement />} />
        <Route path="/categoryManagement" element={<CategoryManagement />} />
        <Route path="/providerManagement" element={<ProviderManagement />} />
        <Route path="/officeManagement" element={<OfficeManagement />} />
        <Route path="/emailRoleManagement" element={<EmailRoleManagement />} />
        <Route path="/documentsRole" element={<DocumentsRoleManagement />} />
        <Route path="/formularioActivos" element={<ActivosManagement />} />
        <Route path="/formularioActivos/create" element={<StepsActivos />} />
        <Route path="/createActivo" element={<StepsActivosRequest />} />
        <Route path="/createActivo/:id" element={<StepsActivosApprove />} />
        <Route path="/activosNoAsignados" element={<ActivosNoAsignadosManagement />} />
        <Route path="/pendingApproval" element={<PendingApprovalManagement />} />
        <Route path="/authority" element={<AuthorityManagement />} />
        <Route path="/notificaciones" element={<NotifyManagement />} />
        <Route
          path="/authoritiesbyrole/:roleId"
          element={<AuthorityByRoleManagement />}
        />
        <Route path="/formularioActivos/update/:activoId" element={<StepsActivos />} />
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Route>
  )
);
