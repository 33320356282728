//Urls Base  
export const UrlBase = {
    base_url: window.REACT_APP_BASE_URL,
    api_context: window.REACT_APP_API_CONTEXT,
    app_login_base_url: window.REACT_APP_LOGIN_BASE_URL,
    registration_x_page: window.REACT_APP_REGISTRATION_X_PAGE,
    modal_registration_x_page: window.REACT_APP_MODAL_REGISTRATION_X_PAGE,
    security_appclient_id: process.env.REACT_APP_SECURITY_APPCLIENT_ID,
    security_appclient_pass: process.env.REACT_APP_SECURITY_APPCLIENT_PASS,
    security_appuser_id: process.env.REACT_APP_SECURITY_APPUSER_ID,
    security_appuser_pass: process.env.REACT_APP_SECURITY_APPUSER_PASS,
    sesion_timeout: process.env.REACT_APP_SESION_TIMEOUT,
    sesion_timeout_wait: process.env.REACT_APP_SESION_TIMEOUT_WAIT,
}