import { URL } from '../../utils/UrlConstants';
import { privateFetch } from '../../utils/CustomFetch.js';
import { properties } from '../../utils/Properties_es';
import { CONSTANT } from '../../utils/Constants';
import { UrlBase } from '../../url/Urls';

export const getRequestEmailRole = async (roleID: string, filter: string, currentPage: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_EMAIL_ROLE(roleID, filter, currentPage, UrlBase.registration_x_page))
    .then(async response => {
      responseData = await response.data;
    })
    .catch(error => {
      throw new Error(properties.com_parval_label_emailrole_find_error);
      //throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_EMAIL_ROLE_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error(properties.com_parval_label_emailrole_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ['email', data.email],
    ['role', data.role.id],
  ]);
  await privateFetch
    .post(
      URL.REQUEST_EMAIL_ROLE_CU,
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error(properties.com_parval_label_emailrole_save_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ['email', data.email],
    ['role', data.role.id],
  ]);
  await privateFetch
    .put(
      `${URL.REQUEST_EMAIL_ROLE_CU}${data.id}`,
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error(properties.com_parval_label_emailrole_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.DELETE_REQUEST_EMAIL_ROLE_ID(id), {})
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error(properties.com_parval_label_emailrole_delete_error);
      //console.log(error)
    });
  return responseData;
};
