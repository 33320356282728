import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { BodyCategory, CategoryResponse, ContentCategory } from "../../types/Category";

export const getCategories = async (page: number, size? :number, filtro?: string): Promise<CategoryResponse> => {

  try {
    const response: AxiosResponse<CategoryResponse> = await privateFetch.get(
      URL.REQUEST_CATEGORY_LIST(
        page,
        size || UrlBase.registration_x_page
      ),
      {
        params: {
          filter: filtro || ""
        }
      }
    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_category_find_error);

  }
};

export const createCategory = async (data: BodyCategory): Promise<ContentCategory> => {
  try {
    const response: AxiosResponse<ContentCategory> = await privateFetch.post(URL.REQUEST_CATEGORY_CREATE, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_category_save_error);
  }
};

export const updateCategory = async (data: BodyCategory): Promise<ContentCategory> => {
  try {
    const response = await privateFetch.put(
      URL.REQUEST_CATEGORY_UPDATE_DELETE(data.id),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_category_update_error);
  }
};


export const deleteCategory = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_CATEGORY_UPDATE_DELETE(id), {});
    return "deleted successfully"
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_category_delete_error);
  }
};

