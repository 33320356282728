import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { BodyEndUser, ContentEndUser, EndUserResponse } from "../../types/EndUser";

export const getEndUsers = async (page: number, size?: number, filtro?: string): Promise<EndUserResponse> => {
  try {

    let params: any = {};

    if (filtro) {
      params.filter = filtro;
    }

    params.status = "ACTIVE"

    const response: AxiosResponse<EndUserResponse> = await privateFetch.get(
      URL.REQUEST_ENDUSER_LIST(
        page,
        size || UrlBase.registration_x_page
      ),
      {
        params: params
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_enduser_find_error);
  }
};

export const getUserWithAseet = async (id: number) => {
  try {
    const response = await privateFetch.get(
      URL.REQUEST_ASSIGNED_ASSET(id)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_enduser_find_error);
  }
}

export const createEndUser = async (data: BodyEndUser): Promise<ContentEndUser> => {
  try {
    const response: AxiosResponse<ContentEndUser> = await privateFetch.post(URL.REQUEST_ENDUSER_CREATE, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_enduser_save_error);
  }
};

export const updateEndUser = async (data: BodyEndUser): Promise<ContentEndUser> => {
  try {
    const response = await privateFetch.put(
      URL.REQUEST_ENDUSER_UPDATE_DELETE(data.id),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_enduser_update_error);
  }
};


export const deleteEndUser = async (id: number, createdUser: string): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_ENDUSER_DELETE(id, createdUser), {});
    return "deleted successfully"
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_enduser_delete_error);
  }
};

