import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  styled,
  FormHelperText,
} from "@mui/material";
import { useApp } from "../../hooks/useApp";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));
const Text = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.greyDue.light,
  fontFamily: "D-dinExp",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "17px",
  padding: theme.spacing(4),
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateRole {
  id?: number;
  adminLogin: boolean;
  clientLogin: boolean;
  description: string;
  name: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateRoleModal = ({
  data,
  onSubmit,
  cancelModal,
}: DialogProps) => {

  //Validación de datos
  const validation = Yup.object().shape({
    adminLogin: Yup.boolean(),
    clientLogin: Yup.boolean(),
    description: Yup.string().required("Campo es requerido"),
    name: Yup.string().required("Campo es requerido"),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    adminLogin: data.adminLogin ? data.adminLogin : false,
    clientLogin: data.clientLogin ? data.clientLogin : false,
    description: data.description ? data.description : "",
    name: data.name ? data.name : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateRole>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {data.id ?  properties.com_parval_label_button_edit_role : properties.com_parval_label_button_add_role}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="description"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                control={control}
                name="adminLogin"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Login Portal Admin"
                  />
                )}
              />
              {errors?.adminLogin && (
                <FormHelperText>{errors.adminLogin.message}</FormHelperText>
              )}
            </Grid> */}
            {/* <Grid item xs={12}>
              <Controller
                control={control}
                name="clientLogin"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Login Portal Ejecutivo"
                  />
                )}
              />
              {errors?.clientLogin && (
                <FormHelperText>{errors.clientLogin.message}</FormHelperText>
              )}
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateRoleModal;
