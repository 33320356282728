import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { PenIcon } from '../components/svgicons/SvgIcons';
import FancyPaper from '../components/FancyPaper';
import FancyTooltip from '../components/utils/FancyTooltip';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import { useForm } from 'react-hook-form';
import { useApp } from '../hooks/useApp';
import { getAllActivos, updateActivo } from '../services/activo/ActivoService';
import { ContentAssets } from '../types/Activo';
import { ContentArea } from '../types/Area';
import { ContentOffice } from '../types/Office';
import { ContentEndUser } from '../types/EndUser';
import { ContentCategory } from '../types/Category';
import { getAreas } from '../services/area/AreaService';
import { getOffices } from '../services/office/OfficeService';
import { getEndUsers } from '../services/endUser/EndUserService';
import { getCategories } from '../services/category/CategoryService';
import Loading from '../components/utils/Loading';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { getActivosOutstanding, updateActivoOutstanding } from '../services/activo/ActivoOutstanding';
import ResourceAccess from '../components/security/ResourceAccess';

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));
const Text = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.greyDue.main,
  fontFamily: "D-dinExp",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "17px",
  padding: theme.spacing(4),
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface searchActivos {
  code: string;
  category: string;
  office: string;
  area: string;
  finalUser: string;
  provider: string;
  scheme: string;
  status: string;
  simulatorDate?: string;
}

const PendingApprovalManagement = () => {
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    authInfo,
    setSuccessMsg,
  } = useApp();
  const theme = useTheme();

  const defaultValues: searchActivos = {
    code: '',
    category: '',
    office: '',
    area: '',
    finalUser: '',
    provider: '',
    scheme: '',
    status: '',
    simulatorDate: ''
  };

  const navigate = useNavigate();
  const [activosData, setActivosData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const { setValue } = useForm();
  const [allAreas, setAllAreas] = useState<ContentArea[]>([]);
  const [allOffices, setAllOffices] = useState<ContentOffice[]>([]);
  const [allAssets, setAllAssets] = useState<ContentAssets[]>([]);
  const [allfinalUsers, setAllfinalUsers] = useState<ContentEndUser[]>([]);
  const [allCategories, setAllCategories] = useState<ContentCategory[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dataActivoToChange, setDataActivoToChange] = useState<ContentAssets | null>(null);

  useEffect(() => {
    let dataInit;
    dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();
    fetchInputs();

    // eslint-disable-next-line
  }, []);

  const fetchInputs = async () => {
    try {
      const results = await Promise.all([
        getOffices(0, 1000),
        getEndUsers(0, 1000),
        getCategories(0, 1000),
        getAreas(0, 1000),
        getAllActivos(),
      ]);

      setAllOffices(results[0].content);
      setAllfinalUsers(results[1].content);
      setAllCategories(results[2].content);
      setAllAreas(results[3].content);
      setAllAssets(results[4]);
    } catch (error) {
      console.log(error);
    }
  };

  const searchNameContent = (id: number, content: any) => {
    let name = '';
    content.forEach((element: any) => {
      if (element.id === id) {
        if (element.lastName) {
          name = `${element.name} ${element.lastName}`;
        } else {
          name = element.name;
        }
      }
    });
    return name;
  };

  const handleFetchData = async (currentPage: number, filter?: searchActivos) => {
    setLoading && setLoading(true);
    try {
      let data = await getActivosOutstanding(currentPage, 10, filter);

      if (data) {
        setActivosData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, defaultValues);
    }
  };

  const handleCancelModalChangeUser = () => {
    setDataActivoToChange(null)
    setOpenModal(false)
    setValue('finalUserChange', '')
  }

  const rejectChangeUser = async () => {
    setLoading && setLoading(true);
    const data = {
      ...dataActivoToChange,
      approval: "Rechazado",
      updateUser: authInfo ? authInfo.name : "Usuario de Prueba",
    }

    try {

      const response = await updateActivoOutstanding(data)
      if (response) {
        await handleFetchData(page, defaultValues);
        handleCancelModalChangeUser()
      }
      setLoading && setLoading(false);


    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  }

  const acceptChangeUser = async () => {
    setLoading && setLoading(true);
    const data = {
      ...dataActivoToChange,
      approval: "Aprobado",
      updateUser: authInfo ? authInfo.name : "Usuario de Prueba",
    }

    const dataUpdate = {
      ...allAssets.find((item) => item.id === dataActivoToChange?.assets),
      finalUser: dataActivoToChange?.finalUser,
      updateUser: authInfo ? authInfo.name : "Usuario de Prueba",
    }

    try {

      const updateActivoResponse = await updateActivo(dataUpdate)


      const response = await updateActivoOutstanding(data)
      if (response && updateActivoResponse) {
        await handleFetchData(page, defaultValues);
        handleCancelModalChangeUser()
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Se ha aprobado el cambio de usuario final")


    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }

  }

  const handleOpenModalChangeUser = (activo: ContentAssets) => {
    setDataActivoToChange(activo)
    setOpenModal(true)
  }

  return (
    <>
      {isLoading && (
        <Loading />
      )}
      <FancyPaper pagetitle='Activos por aprobación'>

        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Tipo de Solicitud</TableCell>
                <TableCell align='center'>Solicitado por</TableCell>
                <TableCell align='center'>Codigo del activo</TableCell>
                <TableCell align='center'>Descripcion</TableCell>
                <TableCell align='center'>Categoria</TableCell>
                <TableCell align='center'>Oficina</TableCell>
                <TableCell align='center'>Area</TableCell>
                <TableCell align='center'>Estado</TableCell>
                <TableCell align='center'>Revisado por</TableCell>
                <ResourceAccess isCode={true} pathOrCode={'ACTIVO:WRITE'}>
                  <TableCell align='center'>Acciones</TableCell>
                </ResourceAccess>


              </TableRow>
            </TableHead>
            <TableBody>
              {activosData &&
                activosData.content &&
                activosData.content.map((row: ContentAssets, i: number) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>

                    <TableCell component='th' scope='row' align='center'>
                      {row.typeRequest ? row.typeRequest : "N/D"}
                    </TableCell>
                    <TableCell component='th' scope='row' align='center'>

                      {row.createUser ? row.createUser : "N/D"}
                    </TableCell>

                    <TableCell component='th' scope='row' align='center'>
                      {row.assets ? allAssets.find((item) => item.id === row.assets)?.code : "N/D"}
                    </TableCell>
                    <TableCell align='center'>{row.description}</TableCell>
                    <TableCell align='center'>{searchNameContent(row.category, allCategories)}</TableCell>
                    <TableCell align='center'>{row.office ? searchNameContent(row.office, allOffices) : "N/D"}</TableCell>
                    <TableCell align='center'>{row.area ? searchNameContent(row.area, allAreas) : "N/D"}</TableCell>
                    <TableCell align='center'>{row.approval ? row.approval === "Pendiente de aprobación" ? (<b>Pendiente de Aprobacion</b>) : row.approval : "N/D"}</TableCell>
                    <TableCell align='center'>{row.updateUser ? row.updateUser : "Sin revisar"}</TableCell>

                     <ResourceAccess isCode={true} pathOrCode={'ACTIVO:WRITE'}>
                      <TableCell align='center'>
                        <FancyTooltip title='Revisión' placement='top'>
                          <IconButton
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            disabled={row.approval === "Aprobado" || row.approval === "Rechazado"}
                            onClick={() => {
                              if (row.typeRequest === "Reasignación de Usuario") {

                                handleOpenModalChangeUser(row)
                              } else {
                                navigate(`/createActivo/${row.id}`)
                              }
                            }}
                          >
                            {/* <ManageSearchIcon /> */}
                            {row.approval === "Rechazado" ? <ThumbDownOffAltIcon /> : row.approval === "Aprobado" ? <ThumbUpOffAltIcon /> : <ManageSearchIcon />}
                          </IconButton>
                        </FancyTooltip>

                      </TableCell>
                    </ResourceAccess>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={activosData?.content?.length > 0 ? activosData?.content?.length : 0}
          rowsPerPage={activosData.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={activosData.totalElements}
          totalPages={activosData.totalPages}
        />
      </FancyPaper>

      <Dialog open={openModal} onClose={handleCancelModalChangeUser} fullWidth>
        <Title id="alert-dialog-title" sx={{ pt: 4, px: 4, display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
          <PenIcon />
          Aprobar cambio de usuario final
        </Title>
        <DialogContent >
          <Text id="alert-dialog-description">
            <Box sx={{
              textAlign: "left",
              fontSize: "17px",
              // color: 'black',
              marginBottom: "2rem"

            }}>

              <Typography fontSize="inherit" >
                Codigo: {allAssets.find((item) => item.id === dataActivoToChange?.assets)?.code}
              </Typography>
              <Typography fontSize="inherit" >
                Descripción:  {dataActivoToChange?.description}
              </Typography>
              <Typography fontSize="inherit" >
                Solicitado por: {dataActivoToChange?.createUser}
              </Typography>
              <Typography fontSize="inherit" >
                Usuario final Nuevo:
                 {allfinalUsers.find((item) => item.id === dataActivoToChange?.finalUser)?.name + " " + allfinalUsers.find((item) => item.id === dataActivoToChange?.finalUser)?.lastName === "undefined undefined" ? " Pendiente de asignar" : allfinalUsers.find((item) => item.id === dataActivoToChange?.finalUser)?.name + " " + allfinalUsers.find((item) => item.id === dataActivoToChange?.finalUser)?.lastName}
              </Typography>
              <Typography fontSize="inherit" >
                Usuario final Actual: {" "}

                {(allfinalUsers.find((item) => item.id === allAssets.find((item) => item.id === dataActivoToChange?.assets)?.finalUser)?.name + " " + allfinalUsers.find((item) => item.id === allAssets.find((item) => item.id === dataActivoToChange?.assets)?.finalUser)?.lastName).includes("undefined") ? "Sin usuario final" : allfinalUsers.find((item) => item.id === allAssets.find((item) => item.id === dataActivoToChange?.assets)?.finalUser)?.name + " " + allfinalUsers.find((item) => item.id === allAssets.find((item) => item.id === dataActivoToChange?.assets)?.finalUser)?.lastName}
              </Typography>


            </Box >


            {/* <Grid item xs={12} lg={4}> */}


            {/* </Grid> */}
          </Text>
        </DialogContent>
        <Actions sx={{ pb: 4, px: 4 }}>

          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={acceptChangeUser}
          >
            Aprobar
          </Button>
          <Button
            variant='contained'
            color='secondary'
            sx={{
              mt: 2,
            }}
            onClick={rejectChangeUser}
            autoFocus>
            Rechazar
          </Button>
          <Button
            variant='contained'
            color='secondary'
            sx={{
              mt: 2,
            }}
            onClick={handleCancelModalChangeUser}
            autoFocus>
            Cerrar
          </Button>{' '}
        </Actions>
      </Dialog>
    </>
  );
};

export default PendingApprovalManagement;

