import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Grid, Box, Typography, Divider, useTheme } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import ComparativeGraph from '../components/ComparativeGraph';
import CardDashboard from '../components/CardDashboard';
import PieChartDashboard from '../components/PieChartDashboard';
import { useForm } from 'react-hook-form';
import { getAssetsByCategory, getAssetsByMonth, getCategoryCount, getProviderCount, getUsersWithAssets } from '../services/dashboard/DashboardService';
import { useApp } from '../hooks/useApp';
import Loading from '../components/utils/Loading';

interface searchData {
  fromDate?: Date | null;
  toDate?: Date | null;
}

const defaultValues: searchData = {
  fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  toDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
};

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [usersWithAssets, setUsersWithAssets] = useState<number>(0);
  const [categoryCount, setCategoryCount] = useState<number>(0);
  const [assetsCategory, setAssetsCategory] = useState<number>(0);
  const [providerCount, setProviderCount] = useState<number>(0);
  const [assetsByMonth, setAssetsByMonth] = useState<number>(0);
  const {
    isLoading,
    setLoading,
  } = useApp();

  const { handleSubmit } = useForm<searchData>({
    defaultValues,
  });

  useEffect(() => {
    fetchData();
    
  }, []);



  const fetchData = async () => {
    setLoading && setLoading(true);
    try {
      const results = await Promise.all([
        getCategoryCount(),
        getUsersWithAssets(),
        getAssetsByCategory(),
        getProviderCount(),
        getAssetsByMonth(),
      ]);
      setCategoryCount(results[0]);
      setUsersWithAssets(results[1]);
      setAssetsCategory(results[2]);
      setProviderCount(results[3]);
      setAssetsByMonth(results[4]);
      setLoading && setLoading(false);

    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const dataName = e.target.name;
    switch (dataName) {
      case 'assetsByMonth':
        const assetsByMonth = await getAssetsByMonth();
        setAssetsByMonth(assetsByMonth);
        break;
      case 'assetsCategory':
        const assetsCategory = await getAssetsByCategory();
        setAssetsCategory(assetsCategory);
        break;
      default:
        fetchData();
        break;
    }
  };

  // @ts-ignore
  const totalCount = assetsCategory ? assetsCategory.slice(1).reduce((acc, item) => acc + item[1], 0) : 0

  return (
    <>      {isLoading && <Loading />}
    <Box
      sx={{
        p: {
          xs: 5,
          md: `${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(6)}`,
        },
      }}>
      <Typography
        variant='caption'
        color='info.dark'
        onClick={() => navigate('/')}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }}>
        Inicio{' '}
      </Typography>
      <Typography variant='caption' color='text.primary'>
        {'/ '}
      </Typography>
      <Divider sx={{ mb: '40px' }} />
      <Grid container spacing={6}>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: '100%' }}
            value={
              usersWithAssets || 0
            }
            title='Usuarios finales'
            subtitle='En los ultimos 3 meses'
            colorIcon='#5271ff'
            iconCard={<PersonAddAlt1Icon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: '100%' }}
            value={
              categoryCount || 0
            }
            title='Número de categorías'
            subtitle='En los ultimos 3 meses'
            colorIcon='#f04438'
            iconCard={<AssignmentIndIcon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: '100%' }}
            value={
              totalCount || 0
            }
            title='Activos fijos'
            subtitle='En los ultimos 3 meses'
            colorIcon='#06833c'
            iconCard={<AccountBalanceIcon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: '100%' }}
            value={providerCount || 0}
            title='Proveedores'
            subtitle='En los ultimos 3 meses'
            colorIcon='#f1b80e'
            iconCard={<PersonAddDisabledIcon />}
          />
        </Grid>
        <Grid item md={7} xs={12}>
          {!!assetsByMonth && (
          <ComparativeGraph
            title='Activos registrados por mes en los ultimos 2 años'
            chartSeries={[assetsByMonth]}
            sx={{ height: '100%' }}
            handleClick={handleSubmit(onSubmit)}
          />
          )}
        </Grid>
        <Grid item md={5} xs={12}>
          {!!assetsCategory && (
            <PieChartDashboard
              title='Categorías de activos'
              data={[assetsCategory]}
              titleChart=' '
              handleClick={handleSubmit(onSubmit)}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
  </>
  );
};

export default Dashboard;
