import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { AxiosResponse } from "axios";

export const getCategoryCount = async (): Promise<number> => {

  try {
    const response: AxiosResponse<number> = await privateFetch.get(
      URL.REQUEST_CATEGORY_COUNT)
    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_dashboard_find_error);
  }
};

export const getUsersWithAssets = async (): Promise<number> => {

  try {
    const response: AxiosResponse<number> = await privateFetch.get(
      URL.REQUEST_USER_WITH_ASSETS)
    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_dashboard_find_error);

  }
}

export const getAssetsByCategory = async (): Promise<number> => {

  try {
    const response: AxiosResponse<number> = await privateFetch.get(
      URL.REQUEST_ASSETS_BY_CATEGORY)
    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_dashboard_find_error);

  }
}

export const getProviderCount = async (): Promise<number> => {

  try {
    const response: AxiosResponse<number> = await privateFetch.get(
      URL.REQUEST_PROVIDER_COUNT)
    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_dashboard_find_error);

  }
}

export const getAssetsByMonth = async (): Promise<number> => {

  try {
    const response: AxiosResponse<number> = await privateFetch.get(
      URL.REQUEST_ASSETS_BY_MONTH)
    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_dashboard_find_error);

  }
}


