import { Box, Paper, Typography, Divider, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

const FancyPaper = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        p: {
          xs: 5,
          md: `${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(6)}`,
        },
      }}
    >
      <Typography
        variant="caption"
        color="info.dark"
        onClick={() => navigate("/")}
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }}
      >
        Inicio{" "}
      </Typography>
      <Typography variant="caption" color="text.primary">
        {"/ "} {props.pagetitle}
      </Typography>
      <Divider />
      <Paper
        sx={{
          padding: {
            xs: 5,
            md: 8,
          },
          mt: {
            xs: 5,
            md: 10,
          },
          mb: 5,
        }}
        {...props}
      >
        {props.children}
      </Paper>
    </Box>
  );
};

export default FancyPaper;
