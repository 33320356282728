import {
  Button,
  Card,
  CardContent,
  CardHeader,
  SvgIcon,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Chart } from "react-google-charts";

const PieChartDashboard = (props: any) => {
  const { data, sx, title, titleChart, handleClick } = props;

  function convertData(inputData: any) {
    inputData = inputData || [];
    if (inputData.length === 0) return;
    const header = inputData[0][0];
    const dataRows = inputData[0].slice(1);
  
    const data = [header];
    //@ts-ignore
    dataRows.forEach(row  => {
      const categoryName = row[0];
      const count = row[1];
      const newRow = [categoryName, count];
      data.push(newRow);
    });
  
    return data;
  }

  return (
    <Card sx={sx}>
      <CardHeader
        action={
          <Button
            color="inherit"
            size="small"
            name="assetsCategory"
            startIcon={
              <SvgIcon fontSize="small">
                <AutorenewIcon />
              </SvgIcon>
            }
            onClick={handleClick}
          >
            Sync
          </Button>
        }
        title={title}
      />
      <CardContent>
        <Chart
          chartType="PieChart"
          data={convertData(data)}
          options={{ title: `${titleChart}` }}
          width={"100%"}
          height={"400px"}
        />
      </CardContent>
    </Card>
  );
};

export default PieChartDashboard;
