import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { MobileTimePicker } from '@mui/x-date-pickers';

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  // officeData: ContentOffice[]
}

const AddOrUpdateNotificationModal = ({
  data,
  onSubmit,
  cancelModal,
}: // officeData
DialogProps) => {
  //Validación de datos
  const validation = Yup.object().shape({
    dia: Yup.string().required('Este campo es requerido'),
  });

  console.log(data)

  const defaultValues = {
    id: data.id ? data.id : 0,
    dia: data.weekDays ? data.weekDays === '2,3,4,5,6' ? '11' : data.weekDays : '8',
    hour: data.hours ? dayjs(`2022-04-17T${data.hours}:${data.minutes}`) : dayjs(`2022-04-17T08:00`)
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
    mode: 'onChange',
  });

  return (
    <>
      <Title id='alert-dialog-title' sx={{ pt: 4, px: 4 }}>
        {data.id ? 'Editar Notificación' : 'Agregar Notificación'}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth='lg' sx={{ pt: 4, pb: 8 }} alignItems='center'>
            <Grid item xs={6}>
              <Controller
                control={control}
                name='dia'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl variant='standard' fullWidth>
                    <InputLabel htmlFor='sucursal-select'>Elegir día</InputLabel>
                    <Select
                      fullWidth
                      error={!!errors.dia && !!errors.dia.message}
                      label=''
                      value={value}
                      onChange={onChange}
                      inputProps={{
                        name: 'sucursal',
                        id: 'sucursal-select',
                      }}>
                      {/* <option value=''>Seleccionar</option> */}
                      <MenuItem value='8'>Todos los días</MenuItem>
                      <MenuItem value='11'>Lunes a viernes</MenuItem>
                      <MenuItem value='2'>Lunes</MenuItem>
                      <MenuItem value='3'>Martes</MenuItem>
                      <MenuItem value='4'>Miercoles</MenuItem>
                      <MenuItem value='5'>Jueves</MenuItem>
                      <MenuItem value='6'>Viernes</MenuItem>
                      <MenuItem value='7'>Sabado</MenuItem>
                      <MenuItem value='1'>Domingo</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            {/* <Grid container spacing={8}> */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name='hour'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      minutesStep={15}
                      views={['hours', 'minutes']}
                      value={value}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </Grid>
          {/* </Grid> */}
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}>
          Aceptar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          Cancelar
        </Button>{' '}
      </Actions>
    </>
  );
};

export default AddOrUpdateNotificationModal;
