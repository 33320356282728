import { URL } from '../../utils/UrlConstants';
import { privateFetch } from '../../utils/CustomFetch.js';
import { properties } from '../../utils/Properties_es';
import { CONSTANT } from '../../utils/Constants';
import { UrlBase } from '../../url/Urls';

export const getRequest = async (page: number, filter: string) => {
  let responseData = {};
  const params = new URLSearchParams([['filter', filter]]);
  await privateFetch
    .get(
      URL.REQUEST_DOCUMENT_LIST(
        page,
        //@ts-ignore
        UrlBase.registration_x_page
      ),
      { params }
    )
    .then(async response => {
      responseData = await response.data;
    })
    .catch(error => {
      throw new Error(properties.com_parval_label_activo_find_error);
    });
  return responseData;
};

export const getRequestAllDocuments = async (page: number, filter: string) => {
  let responseData = {};
  const params = new URLSearchParams([['filter', filter]]);
  await privateFetch
    .get(URL.REQUEST_DOCUMENT_LIST(page, '100'), { params })
    .then(async response => {
      responseData = await response.data;
    })
    .catch(error => {
      throw new Error(properties.com_parval_label_activo_find_error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_DOCUMENT(id), {})
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error(properties.com_parval_label_document_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ['name', data.name],
    ['description', data.description],
    ['maxsizes', Number(data.maxsizes) * 1000000],
    ['createBy', data.createBy],
    ['extension', JSON.stringify(data.extension)],
  ]);

  await privateFetch
    .put(
      URL.REQUEST_DOCUMENT(null),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error(properties.com_parval_label_document_save_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ['id', data.id],
    ['name', data.name],
    ['description', data.description],
    ['maxsizes', Number(data.maxsizes) * 1000000],
    ['createBy', data.createBy],
    ['extension', JSON.stringify(data.extension)],
  ]);
  await privateFetch
    .put(
      URL.REQUEST_DOCUMENT(data.id),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error(properties.com_parval_label_document_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_DOCUMENT(id), {})
    .then(async response => {
      responseData = await response.data;
    })
    .catch(async error => {
      throw new Error(properties.com_parval_label_document_delete_error);
    });
  return responseData;
};
