import * as Yup from "yup";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const numberRegExp = /^[0-9]*$/;

export const urlRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const formSchemaOptions = {
  id: Yup.string().required("Required"),
  role_name: Yup.string().required("Required"),
};
export const formSchemaOptionsName = {
  id: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
};




export interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
}

export const searchNameContent = (id: number, content: any) => {
  let name = '';
  content.forEach((element: any) => {
    if (element.id === id) {
      if (element.lastName) {
        name = `${element.name} ${element.lastName}`;
      } else {
        name = element.name;
      }
    }
  });
  return name || 'No asignado';
};

export function convertirAMoneda(monto: number) {
  const formatoMoneda = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatoMoneda.format(monto);
}
