/*****************************************************************************
 * REVISION 1.0
 * Fecha: 14/01/2023
 *
 * @description JS para manejo de labels
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
export const properties = {
  /** General */

  /** footer */
  com_parval_label_footer_terms_conditions: "Términos y Condiciones",
  /** Page not found */
  com_parval_label_page_404_title: "Oops!",
  com_parval_label_page_404_subtitle: "Página no encontrada",

  /** Loading */
  com_parval_label_loading_title: "Cargando",

  /** Home */
  com_parval_label_home_title: "Parval",

  /** General label */
  com_parval_label_yes: "Si",
  com_parval_label_not: "No",
  com_parval_label_add_subtitle: "Ingrese los datos indicados",
  com_parval_label_update_subtitle: "Modifique los datos indicados",
  com_parval_label_code: "Código",
  com_parval_label_codetfa: "Código TFA",
  com_parval_label_phone: "Teléfono",
  com_parval_label_url: "Url",
  com_parval_label_category: "Categoría",
  com_parval_label_name: "Nombre",
  com_parval_label_lastname: "Apellido",
  com_parval_label_state: "Estado",
  com_parval_label_role: "Perfil",
  com_parval_label_createat: "Fecha de creación",
  com_parval_label_email: "Correo electrónico",
  com_parval_label_password: "Contraseña",
  com_parval_label_passwordrepeat: "Repita contraseña",
  com_parval_label_id: "ID",
  com_parval_label_description: "Descripción",
  com_parval_label_price: "Precio",
  com_parval_label_tax: "ITBIS (18%)",
  com_parval_label_taxes: "Impuesto",
  com_parval_label_department: "Departamento",
  com_parval_label_generationtime: "Tiempo generación",
  com_parval_label_comment: "Comentario",
  com_parval_label_free: "Gratis",
  com_parval_label_zero: "0",
  com_parval_label_select: "Seleccione",
  com_parval_label_select_other: "Otro",
  com_parval_label_not_apply: "No aplica",
  /** General error labels in forms */
  com_parval_label_field_required: "Campo requerido",
  com_parval_label_field_format: "Formato invalido",
  com_parval_label_field_maxlenght: (lenght: string) =>
    `Longitud máxima permitida (${lenght})`,
  com_parval_label_field_minlenght: (lenght: string) =>
    `Longitud minima permitida (${lenght})`,
  /** General sucess labels in process */
  com_parval_label_request_save: "Registro insertado con éxito",
  com_parval_label_request_update: "Registro actualizado con éxito",
  com_parval_label_request_delete: "Registro eliminado con éxito",
  /** General error labels in process */
  com_parval_label_request_connection: "Falla en solicitud al servidor",
  com_parval_label_request_unauthorized:
    "No tiene permiso para realizar esta acción",
  com_parval_label_request_badrequest:
    "Falla en parametros dentro de la solicitud al servidor",

  /** General button labels */
  com_parval_label_button_accept_title: "Aceptar",
  com_parval_label_button_ok_title: "Ok",
  com_parval_label_button_cancel_title: "Cancelar",
  com_parval_label_button_save_title: "Registrar",
  com_parval_label_button_update_title: "Editar",
  com_parval_label_button_detail_title: "Ver detalle",
  com_parval_label_button_delete_title: "Eliminar",
  com_parval_label_button_back_title: "Atras",
  com_parval_label_button_back2_title: "Volver",
  com_parval_label_button_add_title: "Agregar",
  com_parval_label_button_search_title: "Buscar",
  com_parval_label_button_clear_title: "Limpiar",
  com_parval_label_button_login_title: "Iniciar Sesión",
  com_parval_label_button_close_title: "Cerrar",
  com_parval_label_button_view_title: "Ver",
  com_parval_label_button_exit_title: "Salir",
  com_parval_label_button_forgotpass_title: "Recuperar contraseña",
  com_parval_label_button_updatepass_title: "Actualizar contraseña",

  /** Table label */
  com_parval_label_table_filter_title: "Filtrar",
  com_parval_label_table_filter_placeholder: "Filtro de búsqueda",
  com_parval_label_table_action: "Acciones",
  com_parval_label_table_empty: "No existen registros",

  /** General modal label  */
  com_parval_label_modal_delete_title: "Eliminar Registro",

  /** Header dashboard label */
  com_parval_label_dashboard_header_title: "Operaciones",
  /** Menu dashboard label */
  com_parval_label_dashboard_menu_principal: "Portal Administrativo",
  com_parval_label_dashboard_menu_inicio: "Inicio",
  com_parval_label_dashboard_menu_logout: "Cerrar sesión",

  /** Parametros admin menu */
  com_parval_label_dashboard_menu_expedientes: "Expedientes",
  com_parval_label_dashboard_menu_reportes: "Reportes",
  /** configuration admin menu */
  com_parval_label_dashboard_menu_config: "Configuraciones",
  com_parval_label_dashboard_menu_config_user: "Usuarios",
  com_parval_label_dashboard_menu_email_profile: "Perfiles",
  com_parval_label_dashboard_menu_email_permissions: "Permisos",
  com_parval_label_dashboard_menu_email_emailprofile: "Correos por perfil",
  /** login */
  com_parval_label_login_title: "Portal Administrativo",
  com_parval_label_login_forget_password_title: "¿Olvidaste tu contraseña?",
  com_parval_label_login_portal_select_title: "Seleccione portal",
  com_parval_label_login_portal_dd_title: "Due Diligence",
  com_parval_label_login_portal_gl_title: "Gestiones en Línea",
  com_parval_label_login_portal_op_title: "Operaciones",
  /** login success*/
  com_parval_label_login_twofactor_success: "Código TFA enviado al correo",
  /** login error*/
  com_parval_label_login_error: "Error en proceso de inicio de sesión",
  com_parval_label_login_twofactor_error:
    "Error en proceso de enviar código 2fa",
  com_parval_label_login_general_error:
    "Error en proceso de ingresar al portal administrativo",
  com_parval_label_login_logout_error: "Error en proceso de cerrar sesion",
  com_parval_label_login_sessioncode_error:
    "Error en proceso de validar el código de sesión",

  /** RecoveryPassword */
  com_parval_label_recoverypass_title: "Recuperar Contraseña",
  com_parval_label_recoverypass_back_title: "Regresar a login",
  /** ForgotPassword success*/
  com_parval_label_recoverypass_general_sucess:
    "El correo de recuperación a sido enviado",
  /** ForgotPassword error*/
  com_parval_label_recoverypass_general_error:
    "Error en proceso de recuperar contraseña",

  /** ForgotPassword */
  com_parval_label_forgotpass_title: "Cambiar Contraseña",
  /** ForgotPassword success*/
  com_parval_label_forgotpass_general_sucess:
    "El cambio de contraseña se completo",
  /** ForgotPassword error*/
  om_parval_label_forgotpass_different_password_error:
    "Las contraseñas no coinciden",
  com_parval_label_forgotpass_general_error:
    "Error en proceso de cambiar contraseña",

  /** ActiveRegister */
  com_parval_label_activeregister_title: "Activación Usuario",
  /** ActiveRegister success*/
  com_parval_label_activeregister_general_sucess:
    "La activación del usuario se completó",
  com_parval_label_activeregister_general_sucess2:
    "La activación del usuario se completó correctamente.",
  /** ActiveRegister error*/
  com_parval_label_activeregister_general_error:
    "Error en proceso de activar usuario",

  /** Users */
  com_parval_label_user_button_add_title: "Agregar usuario",
  com_parval_label_user_add_title: "Agregar usuario",
  com_parval_label_user_update_title: "Editar usuario",
  com_parval_label_user_update_pass_title: "Actualizar contraseña de usuario",
  /** Role label */
  com_parval_label_button_add_role: "Agregar perfil",
  com_parval_label_button_edit_role: "Editar perfil",
  /** Office Label */
  com_parval_label_button_add_office: "Agregar oficina",
  com_parval_label_button_edit_office: "Editar oficina",

  /** Area label */
  com_parval_label_button_add_area: "Agregar área",
  com_parval_label_button_edit_area: "Editar área",

  /** Provider label */
  com_parval_label_button_add_provider: "Agregar proveedor",
  com_parval_label_button_edit_provider: "Editar proveedor",

  /** Category label */

  com_parval_label_button_add_category: "Agregar categoría",
  com_parval_label_button_edit_category: "Editar categoría",

  /** EndUser label */

  com_parval_label_button_add_enduser: "Agregar usuario final",
  com_parval_label_button_edit_enduser: "Editar usuario final",

  /** Email add */
  com_parval_label_user_button_add_emailprofile: "Agregar correo",
  /** Users service error label */
  com_parval_label_user_find_error: "Error en consulta de usuarios",
  com_parval_label_user_findid_error: "Error en consulta por id de usuario",
  com_parval_label_user_save_error: "Error en proceso de guardar usuario",
  com_parval_label_user_update_error: "Error en proceso de actualizar usuario",
  com_parval_label_user_delete_error: "Error en proceso de eliminar usuario",
  /** Role service error label */
  com_parval_label_role_find_error: "Error en consulta de roles",
  com_parval_label_role_findid_error: "Error en consulta por id de rol",
  com_parval_label_role_save_error: "Error en proceso de guardar rol",
  com_parval_label_role_update_error: "Error en proceso de actualizar rol",
  com_parval_label_role_delete_error: "Error en proceso de eliminar rol",
  /** Area service error label */
  com_parval_label_area_find_error: "Error en consulta de areas",
  com_parval_label_area_save_error: "Error en proceso de guardar area",
  com_parval_label_area_update_error: "Error en proceso de actualizar area",
  com_parval_label_area_delete_error: "Error en proceso de eliminar area",
    /** Assets service error label */
    com_parval_label_assets_find_error: "Error en consulta de activos",
    com_parval_label_assets_save_error: "Error en proceso de guardar activos",
    com_parval_label_assets_update_error: "Error en proceso de actualizar activos",
    com_parval_label_assets_delete_error: "Error en proceso de eliminar activos",
  /** Office service error label */
  com_parval_label_office_find_error: "Error en consulta de oficinas",
  com_parval_label_office_save_error: "Error en proceso de guardar oficina",
  com_parval_label_office_update_error:
    "Error en proceso de actualizar oficina",
  com_parval_label_office_delete_error: "Error en proceso de eliminar oficina",

  /** Provider service error label */
  com_parval_label_provider_find_error: "Error en consulta de proveedores",
  com_parval_label_provider_save_error: "Error en proceso de guardar proveedor",
  com_parval_label_provider_update_error:
    "Error en proceso de actualizar proveedor",
  com_parval_label_provider_delete_error:
    "Error en proceso de eliminar proveedor",

  /** End Users service error label */
  com_parval_label_enduser_find_error: "Error en consulta de usuarios finales",
  com_parval_label_enduser_save_error:
    "Error en proceso de guardar usuario final",
  com_parval_label_enduser_update_error:
    "Error en proceso de actualizar usuario final",
  com_parval_label_enduser_delete_error:
    "Error en proceso de eliminar usuario final",

  /** Category service error label */
  com_parval_label_category_find_error: "Error en consulta de categorías",
  com_parval_label_category_save_error: "Error en proceso de guardar categoría",
  com_parval_label_category_update_error:
    "Error en proceso de actualizar categoría",
  com_parval_label_category_delete_error:
    "Error en proceso de eliminar categoría",

  /** Email Role service error label */
  com_parval_label_emailrole_find_error:
    "Error en consulta de correos por perfil",
  com_parval_label_emailrole_findid_error:
    "Error en consulta por id de correo por perfil",
  com_parval_label_emailrole_save_error:
    "Error en proceso de guardar correo por perfil",
  com_parval_label_emailrole_update_error:
    "Error en proceso de actualizar correo por perfil",
  com_parval_label_emailrole_delete_error:
    "Error en proceso de eliminar correo por perfil",
  /** Email Role service error label */
  com_parval_label_documentRole_find_error:
    "Error en consulta de documentos por perfil",
  com_parval_label_documentRole_findid_error:
    "Error en consulta por id de documento por perfil",
  com_parval_label_documentRole_save_error:
    "Error en proceso de guardar documento por perfil",
  com_parval_label_documentRole_update_error:
    "Error en proceso de actualizar documento por perfil",
  com_parval_label_documentRole_delete_error:
    "Error en proceso de eliminar documento por perfil",
  /** Document service error label */
  com_parval_label_activo_find_error: "Error en consulta de Activos",
  com_parval_label_document_findid_error:
    "Error en consulta por id de documento",
  com_parval_label_document_save_error: "Error en proceso de guardar documento",
  com_parval_label_document_update_error:
    "Error en proceso de actualizar documento",
  com_parval_label_document_delete_error:
    "Error en proceso de eliminar documento",

  /** Dashboard error label */

  com_parval_label_dashboard_find_error: "Error en consulta de dashboard",
};
