import { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Dialog,
  Box,
} from '@mui/material';
import { TrashIcon, PlusIcon, PenIcon } from '../components/svgicons/SvgIcons';
import FancyTooltip from '../components/utils/FancyTooltip';
import FancyPaper from '../components/FancyPaper';
import {  useForm } from 'react-hook-form';

import { properties } from '../utils/Properties_es';
import { useApp } from '../hooks/useApp';
import DeleteModal from '../components/utils/DeleteModal';
import ResourceAccess from '../components/security/ResourceAccess';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import Loading from '../components/utils/Loading';
import { getNotification, createNotification, deleteNotification, updateNotification } from '../services/notificacion/NotificacionService';
import AddOrUpdateNotificationModal from '../components/utils/AddOrUpdateNotification';
import dayjs from 'dayjs';

const initForm = {
  id: 0,
  description: '',
  name: '',
  office: {
    id: 0,
    name: '',
  },
};
type FormData = typeof initForm;

const NotifyManagement = () => {
  const { isLoading, setLoading, setErrorMsg, modalData, setModalData, setSuccessMsg, errorMsg } =
    useApp();
  const theme = useTheme();

  const [notificationData, setNotificationData] = useState<any>({});
  const [page, setPage] = useState<number>(0);
  const [formData, setFormData] = useState<FormData>(initForm);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const { handleSubmit } = useForm();

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (currentPage: number) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage || 0);

      let data = await getNotification(currentPage, 10);
      if (data) {
        setNotificationData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log(data)

    const input = {
      weekDays : data.dia === '8' ? null : data.dia === '11' ? "2,3,4,5,6" : data.dia,
      hours : dayjs(data.hour).format('HH'),
      minutes : dayjs(data.hour).format('mm'),
      allDays: data.dia === '8'
    }

    console.log(input)
    try {
      let createData = await createNotification(input);

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_role_save_error);
        setLoading && setLoading(false);
        console.log('no se envia');
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      console.log('se envio');
      //call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };
  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any): Promise<void> => {
    handleCancelModal();
    setLoading && setLoading(true);

    const input = {
      id: data.id,
      weekDays : data.dia === '8' ? null : data.dia === '11' ? "2,3,4,5,6" : data.dia,
      hours : dayjs(data.hour).format('HH'),
      minutes : dayjs(data.hour).format('mm'),
      allDays: data.dia === '8'
    }


    try {
      
      const updateData = await updateNotification(input);

      if (!updateData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: number) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteNotification(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */

  type ModalAction = 'update' | 'delete';
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute('data-name') as ModalAction;
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');

    if (modalAction === 'update') {
      object = notificationData.content.find((p: any) => p.id === parseInt(id));
      //@ts-ignore
      setFormData(object);
    }

    if (modalAction === 'delete') {
      object = notificationData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        // @ts-ignore
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAdd(data);
        break;
      case 'update':
        await handleUpdate(data);
        break;
      case 'delete':
        // @ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage);
    }
  };

  const days = [
    {
      id: 1,
      name: 'Domingo',
    },
    {
      id: 2,
      name: 'Lunes',
    },
    {
      id: 3,
      name: 'Martes',
    },
    {
      id: 4,
      name: 'Miercoles',
    },
    {
      id: 5,
      name: 'Jueves',
    },
    {
      id: 6,
      name: 'Viernes',
    },
    {
      id: 7,
      name: 'Sabado',
    },
    {
      id: 8,
      name: 'Todos los dias',
    },
    {
      id: "2,3,4,5,6",
      name: 'Lunes a Viernes',
    }
  ];

  return (
    <>
      {isLoading && <Loading />}
      <FancyPaper pagetitle='Notificaciones'>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={8} sm={6} xs={12}>
            {/* <ResourceAccess isCode={true} pathOrCode={'AREA:WRITE'}> */}
            <Button
              variant='contained'
              color='primary'
              onClick={handleOpenModal}
              data-name='create'>
              Agregar Notificación
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            {/* </ResourceAccess> */}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>ID</TableCell>
                <TableCell align='center'>Día</TableCell>
                <TableCell align='center'>Hora</TableCell>
                <TableCell align='center'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notificationData.content &&
                notificationData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell component='th' scope='row' align='center'>
                      {row.id}
                    </TableCell>
                    <TableCell align='center'>
                      {row.allDays
                        ? 'Todos los dias'
                        : days.find(d => d.id == row.weekDays)?.name}
                    </TableCell>
                    <TableCell align='center'>{row.hours + ':' + row.minutes}</TableCell>

                    <TableCell align='center'>
                      {/* <ResourceAccess isCode={true} pathOrCode={'AREA:WRITE'}> */}
                        <FancyTooltip title='Editar' placement='top'>
                          <IconButton
                            aria-label='edit'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name='update'
                            data-id={row.id}>
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title='Eliminar' placement='top'>
                          <IconButton
                            aria-label='trash'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name='delete'>
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      {/* </ResourceAccess> */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

        </TableContainer>
          <FancyTablePagination
            count={notificationData?.content?.length > 0 ? notificationData?.content?.length : 0}
            rowsPerPage={notificationData.size}
            page={page}
            onPageChange={handleChangePage}
            totalElements={notificationData.totalElements}
            totalPages={notificationData.totalPages}
          />
      </FancyPaper>
      {(modalData?.modalType === 'create' || modalData?.modalType === 'update') && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <AddOrUpdateNotificationModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}>
                  Aceptar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus>
                  Cancelar
                </Button>{' '}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default NotifyManagement;
