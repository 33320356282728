import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  useTheme,
  TextField,
  Autocomplete,
  Button,
  Stack,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Box,
} from '@mui/material';
import { PenIcon } from '../components/svgicons/SvgIcons';
import FancyPaper from '../components/FancyPaper';
import FancyTooltip from '../components/utils/FancyTooltip';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import { Controller, useForm } from 'react-hook-form';
import { useApp } from '../hooks/useApp';
import { getActivos, getSimulator, updateActivo } from '../services/activo/ActivoService';
import { ContentAssets } from '../types/Activo';
import moment from 'moment';
import { ContentArea } from '../types/Area';
import { ContentOffice } from '../types/Office';
import { ContentEndUser } from '../types/EndUser';
import { ContentCategory } from '../types/Category';
import { ContentProvider } from '../types/Provider';
import { getAreas } from '../services/area/AreaService';
import { getOffices } from '../services/office/OfficeService';
import { getEndUsers } from '../services/endUser/EndUserService';
import { getCategories } from '../services/category/CategoryService';
import { getProviders } from '../services/provider/ProviderService';
import Loading from '../components/utils/Loading';
import GenerateExcel from '../components/excel/GenerateExcel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { createActivoOutstanding, createNotificacion } from '../services/activo/ActivoOutstanding';
import ResourceAccess from '../components/security/ResourceAccess';
import DescriptionIcon from '@mui/icons-material/Description';

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));
const Text = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.greyDue.main,
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  textAlign: 'center',
  fontSize: '17px',
  padding: theme.spacing(4),
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

interface searchActivos {
  code: string;
  category: string;
  office: string;
  area: string;
  finalUser: string;
  provider: string;
  scheme: string;
  status: string;
  simulatorDate?: string;
  buyDate?: string | null;
  endDate?: string | null;
}

const ActivosNoAsignadosManagement = () => {
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    modalData,
    setModalData,
    errorMsg,
    authInfo,
  } = useApp();
  const theme = useTheme();

  const defaultValues: searchActivos = {
    code: '',
    category: '',
    office: '',
    area: '',
    finalUser: '',
    provider: '',
    scheme: '',
    status: '',
    simulatorDate: '',
    buyDate: null,
    endDate: null,
  };

  const navigate = useNavigate();
  const [activosData, setActivosData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const { handleSubmit, formState, control, register, reset, setValue, watch } = useForm();
  const [allAreas, setAllAreas] = useState<ContentArea[]>([]);
  const [allOffices, setAllOffices] = useState<ContentOffice[]>([]);
  const [allfinalUsers, setAllfinalUsers] = useState<ContentEndUser[]>([]);
  const [allCategories, setAllCategories] = useState<ContentCategory[]>([]);
  const [allProviders, setAllProviders] = useState<ContentProvider[]>([]);
  const [officeSelected, setOfficeSelected] = useState<ContentOffice | null>(null);
  const [dataExcel, setDataExcel] = useState<any[]>([]);
  const [preDataExcel, setPreDataExcel] = useState<any[]>([]);
  const [allActivated, setAllActivated] = useState<boolean>(false);
  const [preFilter, setPreFilter] = useState<searchActivos>(defaultValues);
  const [activoSimulator, setActivoSimulator] = useState<any>(null);
  const [activoSimulated, setActivoSimulated] = useState<any>(null);
  const [openModalChangeUser, setOpenModalChangeUser] = useState<boolean>(false);
  const [dataActivoToChange, setDataActivoToChange] = useState<any>(null);
  const [withFilter, setWithFilter] = useState<boolean>(false);
  const [noteModal, setNoteModal] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');

  const handleCloseModalNote = () => {
    setNoteModal(false);
    setNote('');
  };

  const handleOpendModalNote = (nota: string) => {
    setNoteModal(true);
    setNote(nota);
  };

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const handleCancelModalChangeUser = () => {
    setDataActivoToChange(null);
    setOpenModalChangeUser(false);
    setValue('finalUserChange', '');
    setValue('pending', false);
  };

  const handleOpenModalChangeUser = (data: any) => {
    setDataActivoToChange(data);
    setOpenModalChangeUser(true);
  };

  const handleOpenModal = async (event: any) => {
    activoSimulated && setActivoSimulated(null);
    setValue('simulatorDate', '');
    event.preventDefault();
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');

    const searchActivo = activosData.content.find((item: any) => item.id === parseInt(id));
    setActivoSimulator(searchActivo);

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: 'delete',
        // @ts-ignore
        modalObject: object,
      });
  };

  const { errors } = formState;

  useEffect(() => {
    let dataInit;
    dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();
    fetchInputs();

    // eslint-disable-next-line
  }, []);

  const fetchInputs = async () => {
    try {
      const results = await Promise.all([
        getOffices(0, 1000),
        getEndUsers(0, 1000),
        getCategories(0, 1000),
        getProviders(0, 1000),
        getAreas(0, 1000),
      ]);

      setAllOffices(results[0].content);
      setAllfinalUsers(results[1].content);
      setAllCategories(results[2].content);
      setAllProviders(results[3].content);
      setAllAreas(results[4].content);
    } catch (error) {
      console.log(error);
    }
  };

  const searchNameContent = (id: number, content: any) => {
    let name = '';
    content.forEach((element: any) => {
      if (element.id === id) {
        if (element.lastName) {
          name = `${element.name} ${element.lastName}`;
        } else {
          name = element.name;
        }
      }
    });
    return name || 'No asignado';
  };

  const handleFetchData = async (currentPage: number, filter?: searchActivos) => {
    setLoading && setLoading(true);
    setPreDataExcel([]);
    try {
      let data = await getActivos(currentPage, 10, filter, true);
      const dataExcel = await getActivos(0, 1000, filter, true);
      if (data) {
        setActivosData(data);
        setPreDataExcel(dataExcel.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  /**
   * Validate errors in case of opening modal
   */

  const handleClearFilter = async (e: any) => {
    // setPreFilter(defaultValues);
    setWithFilter(false);
    setAllActivated(false);
    setWithFilter(true);
    setDataExcel([]);
    setOfficeSelected(null);
    reset();
    await handleFetchData(0, defaultValues);
  };

  const handleFilter = async (e: any) => {
    setAllActivated(false);
    setDataExcel([]);
    const filtro = {
      code: e.code,
      category: e.category?.id,
      office: e.office?.id,
      area: e.area?.id,
      finalUser: e.finalUser?.id,
      provider: e.provider?.id,
      scheme: e.scheme?.name,
      status: e.status?.name,
      buyDate: e.buyDate ? moment(e.buyDate).format('MM/DD/YYYY') : null,
      endDate: e.endDate ? moment(e.endDate).format('MM/DD/YYYY') : null,
    };
    setPreFilter(filtro);
    if (
      filtro.office ||
      filtro.area ||
      filtro.finalUser ||
      filtro.provider ||
      filtro.category ||
      filtro.code ||
      filtro.scheme ||
      filtro.status ||
      (filtro.buyDate && filtro.endDate)
    ) {
      await handleFetchData(0, filtro);
    }
  };

  function obtenerAreaPorOficina(officeId: number) {
    const areasPorOficina = allAreas.filter(area => area.office === officeId);
    return areasPorOficina;
  }

  const selectAllDataToExcel = async (value: any) => {
    setAllActivated(value.target.checked);
    if (value.target.checked) {
      const transformData = preDataExcel.map(item => ({
        id: item.id,
      }));
      setDataExcel(transformData);
    } else {
      setDataExcel([]);
    }
  };

  const selectActivoToExcel = async (value: any, id: number) => {
    if (value.target.checked) {
      const data = preDataExcel.find(item => item.id === id);
      const transformData = {
        id: data?.id,
      };
      setDataExcel([...dataExcel, transformData]);
    } else {
      setDataExcel(dataExcel.filter(item => item.id !== id));
    }
  };

  const onSubmit = async (data: any) => {
    setLoading && setLoading(true);
    const convertDate = moment(data.simulatorDate).format('MM/DD/YYYY');
    try {
      const searchSimulator = await getSimulator(activoSimulator?.id, convertDate);
      setActivoSimulated(searchSimulator);

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const onSubmitChangeUserRequestApprobe = async (data: any) => {
    // setLoading && setLoading(true);
    const newActivo = {
      ...dataActivoToChange,
      assets: dataActivoToChange.id,
      finalUser: data.finalUserChange.id,
      approval: 'Pendiente de aprobación',
      createUser: authInfo ? authInfo.name : 'Prueba',
      typeRequest: 'Reasignación de Usuario',
    };

    try {
      await createActivoOutstanding(newActivo);
      await createNotificacion(
        authInfo ? authInfo.name : 'Usuario de prueba',
        authInfo ? authInfo.username : 'Correo de prueba'
      );

      setLoading && setLoading(false);
      setOpenModalChangeUser(false);
      setSuccessMsg && setSuccessMsg('Cambio de usuario de activo enviado para aprobación.');
      navigate('/pendingApproval');
    } catch (error: any) {
      setLoading && setLoading(false);
      setOpenModalChangeUser(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const onSubmitChangeUser = async (data: any) => {
    setLoading && setLoading(true);

    const updateActivoData = {
      ...dataActivoToChange,
      finalUser: watchPending ? null : data.finalUserChange.id,
      updateUser: authInfo ? authInfo.name : 'Probando',
    };

    try {
      await updateActivo(updateActivoData);
      setLoading && setLoading(false);
      setOpenModalChangeUser(false);
      setSuccessMsg && setSuccessMsg('Cambio de usuario completado.');
      handleFetchData(0, preFilter);
      setValue('finalUserChange', '');
      navigate('/formularioActivos');
    } catch (error: any) {
      setLoading && setLoading(false);
      setOpenModalChangeUser(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const watchSimulatorDate = watch('simulatorDate');
  const watchFinalUserChange = watch('finalUserChange');
  const watchPending = watch('pending');
  const watchbuyDate = watch('buyDate');
  const watchendDate = watch('endDate');

  function convertirAMoneda(monto: number) {
    const formatoMoneda = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatoMoneda.format(monto);
  }

  useEffect(() => {
    if (watchPending) {
      setValue('finalUserChange', '');
    }
  }, [watchPending, setValue]);

  return (
    <>
      {isLoading && <Loading />}
      <FancyPaper pagetitle='Activos'>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 700, mb: 2, fontSize: '1.06250rem' }}
              color='greyDue.dark'>
              Filtrar por:
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={'code'}
              defaultValue={''}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label='Código'
                  type='text'
                  variant='standard'
                  fullWidth
                  value={value}
                  {...register('code')}
                  sx={{
                    '& input': {
                      pl: '0!important',
                    },
                  }}
                  error={errors.code && Boolean(errors.code)}
                  // helperText={errors.code && errors.code.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              control={control}
              name='category'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  value={value || null}
                  options={allCategories}
                  getOptionLabel={(option: any) => option.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Categoria'
                      variant='standard'
                      error={!!errors.fileForm}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Controller
              control={control}
              name='office'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                    setOfficeSelected(newValue);
                  }}
                  value={value || null}
                  options={allOffices}
                  getOptionLabel={(option: any) => option.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Oficina'
                      variant='standard'
                      error={!!errors.fileForm}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              control={control}
              name='area'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  value={value || null}
                  options={obtenerAreaPorOficina(officeSelected?.id || 0)}
                  getOptionLabel={(option: any) => option.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Area'
                      variant='standard'
                      error={!!errors.fileForm}
                      // helperText={errors.fileForm && errors.fileForm.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              control={control}
              name='provider'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  value={value || null}
                  options={allProviders}
                  getOptionLabel={(option: any) => option.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Proveedor'
                      variant='standard'
                      error={!!errors.fileForm}
                      // helperText={errors.fileForm && errors.fileForm.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              control={control}
              name='scheme'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  value={value || null}
                  options={[
                    { id: 1, name: 'Activo Propio' },
                    { id: 2, name: 'Leasing' },
                  ]}
                  getOptionLabel={(option: any) => option.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Tipo de activo'
                      variant='standard'
                      error={!!errors.fileForm}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              control={control}
              name='status'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  value={value || null}
                  options={[
                    { id: 1, name: 'Activo vigente' },
                    { id: 2, name: 'Activo Depreciado' },
                  ]}
                  getOptionLabel={(option: any) => option.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Status del activo'
                      variant='standard'
                      error={!!errors.fileForm}
                      // helperText={errors.fileForm && errors.fileForm.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={'buyDate'}
              defaultValue={''}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label='Desde'
                  type='date'
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={value}
                  {...register('buyDate')}
                  sx={{
                    '& input': {
                      pl: '0!important',
                    },
                  }}
                  error={errors.buyDate && Boolean(errors.buyDate)}
                  // helperText={errors.code && errors.code.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={'endDate'}
              defaultValue={''}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label='Hasta'
                  type='date'
                  variant='standard'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={value}
                  {...register('endDate')}
                  sx={{
                    '& input': {
                      pl: '0!important',
                    },
                  }}
                  error={errors.endDate && Boolean(errors.endDate)}
                  // helperText={errors.code && errors.code.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' justifyContent='flex-end' spacing={2.5}>
              <GenerateExcel
                data={dataExcel}
                exportAllData={allActivated}
                withFilter={withFilter}
                allAreas={allAreas}
                allCategories={allCategories}
                allfinalUsers={allfinalUsers}
                allOffices={allOffices}
                allProviders={allProviders}
              />
              <Button
                variant='contained'
                onClick={handleSubmit(handleFilter)}
                color='primary'
                disabled={(watchbuyDate && !watchendDate) || (!watchbuyDate && watchendDate)}>
                Buscar
              </Button>
              <Button variant='contained' color='secondary' onClick={handleClearFilter}>
                Limpiar
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <input type='checkbox' checked={allActivated} onChange={selectAllDataToExcel} />
                </TableCell>

                <TableCell>Codigo</TableCell>
                <TableCell align='center'>Descripcion</TableCell>
                <TableCell align='center'>Categoria</TableCell>
                <TableCell align='center'>Oficina</TableCell>
                <TableCell align='center'>Area</TableCell>
                {/* <TableCell align='center'>Usuario Final</TableCell> */}
                <TableCell align='center'>Serie</TableCell>
                <TableCell align='center'>Vida util</TableCell>
                <TableCell align='center'>% Anual</TableCell>
                <TableCell align='center'>Fecha de Compra</TableCell>
                <TableCell align='center'>Fecha de Expiración</TableCell>
                <TableCell align='center'>Status</TableCell>
                <TableCell align='center'>Tipo de activo</TableCell>
                <TableCell align='center'>Fecha de Garantia</TableCell>
                <TableCell align='center'>NCF</TableCell>
                <TableCell align='center'>Valor de Compra</TableCell>
                <TableCell align='center'>Balance Inicial</TableCell>
                <TableCell align='center'>Depreciación Acumulada</TableCell>
                <TableCell align='center'>Valor en libros</TableCell>
                <TableCell align='center'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activosData &&
                activosData.content &&
                activosData.content.map((row: ContentAssets, i: number) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell>
                      <input
                        type='checkbox'
                        onChange={e => selectActivoToExcel(e, row.id)}
                        checked={dataExcel.find(item => item.id === row.id) || false}
                      />
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {row.code}
                    </TableCell>
                    <TableCell align='center'>{row.description}</TableCell>
                    <TableCell align='center'>
                      {searchNameContent(row.category, allCategories)}
                    </TableCell>
                    <TableCell align='center'>
                      {row.office ? searchNameContent(row.office, allOffices) : 'N/A'}
                    </TableCell>
                    <TableCell align='center'>
                      {row.area ? searchNameContent(row.area, allAreas) : 'N/A'}
                    </TableCell>
                    {/* <TableCell align='center'>No asignado</TableCell>*/}
                    <TableCell align='center'>{row.serie ? row.serie : 'N/D'}</TableCell>
                    <TableCell align='center'>
                      {row.depreciationYear ? row.depreciationYear : 'N/D'}
                    </TableCell>
                    <TableCell align='center'>
                      {row.anual ? ` ${row.anual} ${row.anual.includes('%') ? '' : '%'}` : 'ND'}
                    </TableCell>
                    <TableCell align='center'>
                      {row.buyDate ? moment(row.buyDate).format('DD-MM-YYYY') : 'N/D'}
                    </TableCell>
                    <TableCell align='center'>
                      {row.expirationDate ? moment(row.expirationDate).format('DD-MM-YYYY') : 'N/D'}
                    </TableCell>
                    <TableCell align='center'>{row.status ? row.status : 'N/D'}</TableCell>
                    <TableCell align='center'>
                      {row.scheme
                        ? row.scheme === 'leasing'
                          ? 'Leasing'
                          : 'Activo Propio'
                        : 'N/D'}
                    </TableCell>
                    <TableCell align='center'>
                      {row.warrantyDate ? moment(row.warrantyDate).format('DD-MM-YYYY') : 'N/D'}
                    </TableCell>
                    <TableCell align='center'>{row.ncf ? row.ncf : 'N/D'}</TableCell>
                    <TableCell align='center'>
                      {row.amountBuy ? convertirAMoneda(row.amountBuy) : 'N/D'}
                    </TableCell>
                    <TableCell align='center'>
                      {row.initialBalance ? convertirAMoneda(row.initialBalance) : '0'}
                    </TableCell>
                    <TableCell align='center'>
                      {row.accumulatedDepreciation
                        ? convertirAMoneda(row.accumulatedDepreciation)
                        : row.scheme === 'leasing'
                        ? ''
                        : 0}
                    </TableCell>
                    <TableCell align='center'>
                      {row.bookValue
                        ? convertirAMoneda(row.bookValue)
                        : row.scheme === 'leasing'
                        ? ''
                        : 0}
                    </TableCell>

                    <TableCell align='center'>
                      <ResourceAccess isCode={true} pathOrCode={'ACTIVOAPPROVAL:REQUEST'}>
                        <FancyTooltip title='Cambiar usuario' placement='top'>
                          <IconButton
                            aria-label='edit'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={() => handleOpenModalChangeUser(row)}
                            data-name='update'
                            data-id={row.id}>
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>

                      </ResourceAccess>
                      {row.note && (
                          <FancyTooltip title='Nota de activo' placement='top'>
                            <IconButton
                              aria-label='edit'
                              component='label'
                              color='secondary'
                              sx={{
                                '&:hover': {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              disabled={!row.note}
                              onClick={() => handleOpendModalNote(row.note || '')}
                              data-id={row.id}>
                              <DescriptionIcon />
                            </IconButton>
                          </FancyTooltip>
                        )}
                      <ResourceAccess isCode={true} pathOrCode={'ACTIVO:WRITE'}>
                        <FancyTooltip title='Editar' placement='top'>
                          <IconButton
                            aria-label='edit'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={() => navigate(`/formularioActivos/update/${row.id}`)}
                            data-name='update'
                            data-id={row.id}>
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title='Simulador' placement='top'>
                          <IconButton
                            aria-label='trash'
                            disabled={row.scheme === 'leasing'}
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name='simulator'>
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={activosData?.content?.length > 0 ? activosData?.content?.length : 0}
          rowsPerPage={activosData.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={activosData.totalElements}
          totalPages={activosData.totalPages}
        />
      </FancyPaper>

      <Dialog open={modalData!!.modalOpen} onClose={handleCancelModal} fullWidth>
        <Title
          id='alert-dialog-title'
          sx={{
            pt: 4,
            px: 4,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}>
          <RemoveRedEyeIcon />
          Simulador de Activos Fijos
        </Title>
        <DialogContent>
          <Text id='alert-dialog-description'>
            <Box
              sx={{
                textAlign: 'left',
                fontSize: '17px',
                // color: 'black',
                marginBottom: '2rem',
              }}>
              <Typography fontSize='inherit'>Codigo: {activoSimulator?.code}</Typography>
              <Typography fontSize='inherit'>
                Descripccion: {activoSimulator?.description}
              </Typography>
              <Typography fontSize='inherit'>
                Valor de compra: {activoSimulator?.amountBuy}
              </Typography>
              <Typography fontSize='inherit'>
                Balance inicial: {activoSimulator?.initialBalance}
              </Typography>
              <Typography fontSize='inherit'>
                Fecha de Compra: {moment(activoSimulator?.buyDate).format('DD-MM-YYYY')}
              </Typography>
              <Typography fontSize='inherit'>
                Fecha de Expiración: {moment(activoSimulator?.expirationDate).format('DD-MM-YYYY')}
              </Typography>

              <Typography fontSize='inherit'>
                Depreciacion acumulada: {activoSimulator?.accumulatedDepreciation}
              </Typography>
              <Typography fontSize='inherit'>
                Valor en libros: {activoSimulator?.bookValue} al {moment().format('DD-MM-YYYY')}
              </Typography>
              {activoSimulated && (
                <>
                  <Typography fontSize='inherit'>
                    Depreciacion acumulada simulada : {activoSimulated?.accumulatedDepreciation}
                  </Typography>
                  <Typography fontSize='inherit'>
                    Valor en libros simulada: {activoSimulated?.bookValue}
                  </Typography>
                </>
              )}
            </Box>

            {/* <Grid item xs={12} lg={4}> */}
            <Controller
              name={'simulatorDate'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size='small'
                  label='Fecha'
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant='standard'
                  value={value}
                  sx={{
                    maxWidth: '300px',

                    '& input': {
                      pl: '0!important',
                    },
                  }}
                  {...register('simulatorDate')}
                />
              )}
            />
            {/* </Grid> */}
          </Text>
        </DialogContent>
        <Actions sx={{ pb: 4, px: 4 }}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={!watchSimulatorDate}>
            Simular
          </Button>
          <Button
            variant='contained'
            color='secondary'
            sx={{
              mt: 2,
            }}
            onClick={handleCancelModal}
            autoFocus>
            Cerrar
          </Button>{' '}
        </Actions>
      </Dialog>

      <Dialog open={noteModal} onClose={handleCloseModalNote} fullWidth>
        <Title
          id='alert-dialog-title'
          sx={{
            pt: 4,
            px: 4,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}>
          <DescriptionIcon />
          Nota
        </Title>
        <DialogContent>
          <Text id='alert-dialog-description'>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography fontSize='inherit'>{note}</Typography>
            </Box>
          </Text>
        </DialogContent>
        <Actions sx={{ pb: 4, px: 4 }}>
          <Button
            variant='contained'
            color='secondary'
            sx={{
              mt: 2,
            }}
            onClick={handleCloseModalNote}
            autoFocus>
            Cerrar
          </Button>{' '}
        </Actions>
      </Dialog>

      <Dialog open={openModalChangeUser} onClose={handleCancelModalChangeUser} fullWidth>
        <Title
          id='alert-dialog-title'
          sx={{
            pt: 4,
            px: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}>
          <PenIcon />
          Cambiar usuario final
        </Title>
        <DialogContent>
          <Text id='alert-dialog-description'>
            <Box
              sx={{
                textAlign: 'left',
                fontSize: '17px',
                // color: 'black',
                marginBottom: '2rem',
              }}>
              <Typography fontSize='inherit'>Codigo: {dataActivoToChange?.code}</Typography>
              <Typography fontSize='inherit'>
                Descripción: {dataActivoToChange?.description}
              </Typography>
              <Typography fontSize='inherit'>
                Usuario final actual:{' '}
                {searchNameContent(dataActivoToChange?.finalUser, allfinalUsers)}
              </Typography>
            </Box>

            <Controller
              control={control}
              name='finalUserChange'
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  value={value || null}
                  options={allfinalUsers}
                  getOptionLabel={(option: any) => `${option.name} ${option.lastName}` || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  disabled={watchPending}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{
                        maxWidth: '300px',
                      }}
                      label='Usuario Final'
                      variant='standard'
                      error={!!errors.fileForm}
                    />
                  )}
                />
              )}
            />

            {/* </Grid> */}
          </Text>
        </DialogContent>
        <Actions sx={{ pb: 4, px: 4 }}>
          <ResourceAccess isCode={true} pathOrCode={'ACTIVOAPPROVAL:REQUEST'}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleSubmit(onSubmitChangeUserRequestApprobe)}
              // disabled={!watchSimulatorDate}
              disabled={!watchFinalUserChange}>
              Cambiar
            </Button>
          </ResourceAccess>
          <ResourceAccess isCode={true} pathOrCode={'ACTIVO:WRITE'}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              sx={{
                mt: 2,
                mr: 2,
              }}
              onClick={handleSubmit(onSubmitChangeUser)}
              // disabled={!watchSimulatorDate}
              disabled={!watchFinalUserChange}>
              Cambiar
            </Button>
          </ResourceAccess>
          <Button
            variant='contained'
            color='secondary'
            sx={{
              mt: 2,
            }}
            onClick={handleCancelModalChangeUser}
            autoFocus>
            Cerrar
          </Button>{' '}
        </Actions>
      </Dialog>
    </>
  );
};

export default ActivosNoAsignadosManagement;
